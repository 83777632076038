import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { NewsGet } from "../../../stores/actions/newsAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  CommentCreate,
  CommentGet,
} from "../../../stores/actions/commentAction";
import {
  getAllUser,
  getLoginUserData,
} from "../../../stores/actions/authAction";

// import { CiImageOff } from "react-icons/ci";

import { ProgressBar } from "react-loader-spinner";

import { Helmet } from "react-helmet";
const { BASE_URL } = require("../../../config/dev");

export default function NewsDetail() {
  let newsId = localStorage.getItem("newsId");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Id } = useParams();

  const {
    articalData,
    articalError,
    articalSuccessMessage,
    articalSuccessMessagee,
  } = useSelector((state) => state.news);

  const {
    commentData,
    commentError,
    commentSuccessMessage,
    commentSuccessMessagee,
  } = useSelector((state) => state.comment);

  const {
    successMessage,
    error,
    authenticate,
    userOtp,
    curUserData,
    userRData,
    myInfo,
  } = useSelector((state) => state.auth);

  const loginUserId = myInfo.Id; //myInfo.Id

  const [curNews, setCurNews] = useState({});

  const [curIndex, setcurIndex] = useState();

  useEffect(() => {
    if (articalData && articalData.length > 0 && newsId) {
      articalData.map((sa, index) => {
        console.log(sa);
        if (sa._id === newsId) {
          setCurNews(sa);
          setcurIndex(index + 1);
        }
      });
    }
  }, [articalData, Id]); // removed Id as dependency

  const [commentState, setCommentState] = useState([]);

  useEffect(() => {
    if (
      userRData &&
      userRData.length > 0 &&
      commentData &&
      commentData.length > 0
    ) {
      let curData = [];
      for (let i = 0; i < commentData.length; i++) {
        for (let j = 0; j < userRData.length; j++) {
          if (
            userRData[j]._id.toString() === commentData[i].userId.toString()
          ) {
            curData.push({
              userId: userRData[j]._id,
              userName: `${userRData[j].fname} ${userRData[j].lname}`,
              commentId: commentData[i]._id,
            });
          }
        }
      }
      setCommentState(curData);
    }
  }, [userRData && commentData]);

  const handelNews = (data) => {
    localStorage.setItem("newsId", data._id);
    let Id = data.headingName
      .split(" ")
      .map((value) => {
        return value.trim().toLowerCase();
      })
      .join("-");
    // console.log(Id)
    setCommentState([]);
    navigate(`/news-details/${Id}`);
  };

  let obj = {};
  useEffect(() => {
    obj = {
      commentText: "",
      newsId: newsId,
      userId: loginUserId,
      createTime: "",
    };
  }, [curNews]);

  const [loaders, setLoaders] = React.useState(false);

  const [state, setState] = useState(obj);

  const handelCreate = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  /* For Time */
  const handelTime = (e) => {
    const date = new Date(Date.now());

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };

    return date.toLocaleString("en-US", options);
  };

  /*Comment submit */
  const handelSubmit = () => {
    let data = {
      commentText: state.commentText,
      newsId: newsId,
      userId: loginUserId,
      createTime: handelTime(),
    };
    setLoaders(true);
    dispatch(CommentCreate(data)).then((result) => {
      setLoaders(false);
      setState({});
      dispatch(CommentGet({ newsId: newsId }));
    });
  };

  // wrt reload comment get
  useEffect(() => {
    dispatch(CommentGet({ newsId: newsId }));
  }, [Id]);

  const shareFacebook = (data) => {
    // console.log(BASE_URL + window.location.pathname);

    let facebookSlug = data.headingName
      .split(" ")
      .map((value) => {
        return value.trim().toLowerCase();
      })
      .join("-");

    facebookSlug = BASE_URL + "/news-details/" + facebookSlug;

    // console.log(facebookSlug);

    let targetUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      facebookSlug
    )}`;

    window.open(
      targetUrl,
      "_blank",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    );
  };

  const shareLinkedin = (data) => {
    let facebookSlug = data.headingName
      .split(" ")
      .map((value) => {
        return value.trim().toLowerCase();
      })
      .join("-");

    facebookSlug = BASE_URL + "/news-details/" + facebookSlug;

    // console.log(facebookSlug)

    let targetUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      facebookSlug
    )}&title=${
      data.headingName
    }&summary="Click TO know More"&source=${encodeURIComponent(facebookSlug)}`;

    // const facebookShareURL = `https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Furl%3Dhttp%253A%252F%252Fgithub.com%26mini%3Dtrue`;

    // `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`http://localhost:3000/news-details/${facebookSlug}`)}`;

    window.open(
      targetUrl,
      "_blank",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    );
  };

  function slugify(str) {
    return String(str)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
  }

  return (
    <>
      <Helmet>
        <title>Planet Quizzer - Artical</title>
        <meta name="description" content={curNews.description} />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta property="og:description" content={curNews.description} />
        <meta
          property="og:image"
          content={
            curNews && curNews.image
              ? curNews.image
              : BASE_URL + "/" + "images/profilePic.png"
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link
          rel="image_src"
          href={
            curNews && curNews.image
              ? curNews.image
              : BASE_URL + "/" + "images/profilePic.png"
          }
        ></link>
      </Helmet>

      <div>
        {curNews && articalData && articalData.length > 0 ? (
          <div className="container-fluid">
            {/* <header w3-include-html="header.html"></header> */}

            <div className="row news-detail" key="">
              <div className="col-sm-12 col-md-8 mb-4 mt-4 news-left">
                <h1 className="page-title mt-4 mb-4">
                  <span className="blue">News</span>
                </h1>

                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-number">{curIndex}</span>
                      </div>

                      <div className="card-body">
                        <h5 className="card-title">{curNews.headingName}</h5>
                      </div>

                      <img
                        src={
                          curNews && curNews.image
                            ? curNews.image
                            : "https://placehold.co/600x400/png"
                        }
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="news-content">
                      {/* <p>{curNews.descriptions}</p> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: curNews.descriptions,
                        }}
                      />

                      <div className="all-icons">
                        <a
                          className="icon"
                          // onClick={() => shareFacebook(curNews)}
                          href={`https://www.facebook.com/sharer/sharer.php?u=${
                            window.location.protocol
                          }//${window.location.hostname}/news-details/${slugify(
                            curNews.headingName
                          )}`}
                        >
                          <i className="fab fa-lg fa-facebook-f"></i>
                        </a>

                        {/* <a
                      className="icon"
                      onClick={() => shareTwitter(curNews)}
                      href="/#"
                    >
                      <i className="fab fa-lg fa-twitter"></i>
                    </a> */}

                        <a
                          className="icon"
                          // onClick={() => shareLinkedin(curNews)}
                          href={`https://www.linkedin.com/shareArticle?url=${
                            window.location.protocol
                          }//${window.location.hostname}/news-details/${slugify(
                            curNews.headingName
                          )}&title=${curNews.headingName}&summary=${
                            curNews.headingName
                          }&source=${window.location.href}`}
                        >
                          <i className="fab fa-lg fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>

                    {/* {myInfo && myInfo.fname && myInfo.lname && myInfo.fname.length > 0 && myInfo.lname.leght > 0 ? */}

                    {loginUserId ? (
                      <>
                        <div className="comment-form mb-4">
                          <form>
                            <div className="form-floating mt-4 mb-4">
                              <textarea
                                type="text"
                                name="commentText"
                                onChange={(e) => handelCreate(e)}
                                className="form-control"
                                id="commentText"
                                placeholder="Enter Comment"
                              >
                                {state && state.commentText ? state.commentText : ""}
                              </textarea>

                              <label htmlFor="password">Comment here</label>
                            </div>

                            <button
                              className="comment-btn"
                              type="button"
                              style={{ width: "150px" }}
                              onClick={() => handelSubmit()}
                            >
                              {loaders ? (
                                <i class="fa fa-spinner fa-spin"></i>
                              ) : null}
                              &nbsp;&nbsp;Send
                            </button>
                          </form>
                        </div>
                      </>
                    ) : (
                      <div>
                        <p> You have to login to comment</p>
                      </div>
                    )}

                    {/* : null} */}
                  </div>
                  <div className="col-lg-12">
                    <div className="comment-list">
                      <h3>Comments</h3>
                      <ul>
                        {commentData && commentData.length > 0
                          ? commentData.map((value, index) => {
                              // console.log(354,value)
                              let curName = "";
                              if (
                                value &&
                                commentState &&
                                commentState.length > 0
                              ) {
                                for (let i = 0; i < commentState.length; i++) {
                                  // console.log(commentState)
                                  if (commentState[i].commentId === value._id) {
                                    curName = commentState[i].userName;
                                  }
                                }
                              }
                              return (
                                <li className="mb-4">
                                  <div className="comment-author">
                                    <img
                                      alt=""
                                      className="bi me-2"
                                      width="15"
                                      role="img"
                                      src="../../images/profile.svg"
                                    />

                                    <span className="green-two">{curName}</span>
                                    <p className="text-font-9">
                                      {value.commentText}
                                    </p>
                                    <span className="float-end">
                                      {value.createTime} &nbsp; &nbsp;
                                      {/* {value.createdAt} */}
                                      {new Date(value.createdAt).toDateString()}
                                    </span>
                                  </div>
                                </li>
                              );
                            })
                          : // <div
                            //   style={{
                            //     display: "flex",
                            //     justifyContent: "center",
                            //     alignItems: "center",
                            //     position: "absolute",
                            //     top: "0",
                            //     bottom: "0",
                            //     left: "0",
                            //     right: "0",
                            //     backgroundColor: "rgba(255, 255, 255, 0.8)",
                            //   }}
                            // >
                            //   <ProgressBar
                            //     height="80"
                            //     width="80"
                            //     ariaLabel="progress-bar-loading"
                            //     wrapperStyle={{}}
                            //     wrapperClass="progress-bar-wrapper"
                            //     borderColor="#156EF0"
                            //     barColor="#019317"
                            //   />
                            // </div>
                            null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 mb-4 mt-4 news-right">
                <h1 className="page-subtitle mt-4 mb-4">
                  <span className="blue">More</span>
                  <span className="green">News</span>
                </h1>

                {articalData && articalData.length > 0
                  ? articalData.map((value, index) => {
                      return (
                        <div
                          className="card bg-blue mt-4 mb-4"
                          key={index}
                          onClick={() => handelNews(value)}
                        >
                          <div className="card-header">
                            <span className="card-number">{index + 1}</span>

                            <div className="all-icons">
                              <a
                                className="icon"
                                // onClick={() => shareFacebook(curNews)}
                                href={`https://www.facebook.com/sharer/sharer.php?u=${
                                  window.location.protocol
                                }//${
                                  window.location.hostname
                                }/news-details/${slugify(value.headingName)}`}
                              >
                                <i className="fab fa-lg fa-facebook-f"></i>
                              </a>

                              <a
                                className="icon"
                                // onClick={() => shareLinkedin(value)}
                                href={`https://www.linkedin.com/shareArticle?url=${
                                  window.location.protocol
                                }//${
                                  window.location.hostname
                                }/news-details/${slugify(
                                  value.headingName
                                )}&title=${value.headingName}&summary=${
                                  value.headingName
                                }&source=${window.location.href}`}
                              >
                                <i className="fab fa-lg fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>

                          <div className="pro-picture">
                            <img
                              src={
                                value && value.image
                                  ? value.image
                                  : "https://placehold.co/600x400/png"
                              }
                              className="card-img-top"
                              alt="..."
                            />
                          </div>

                          <div className="card-body">
                            <h5 className="card-title">{value.headingName}</h5>
                          </div>
                        </div>
                      );
                    })
                  : null}

                {/* <div className="card bg-green mt-4 mb-4">
                            <div className="card-header">
                                <span className="card-number">02</span>
                                <div className="all-icons">
                                    <a href='/#' className="icon">
                                        <i className="fab fa-lg fa-facebook-f"></i>
                                    </a>
                                    <a href='/#' className="icon">
                                        <i className="fab fa-lg fa-instagram"></i>
                                    </a>
                                    <a href='/#' className="icon">
                                        <i className="fab fa-lg fa-google"></i>
                                    </a>
                                    <a href='/#' className="icon">
                                        <i className="fab fa-lg fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                            <img src="./images/news.png" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Lorem ipsum dolor sit amet consectetur.</h5>
                            </div>
                        </div>  */}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#156EF0"
              barColor="#019317"
            />
          </div>
        )}
      </div>
    </>
  );
}
