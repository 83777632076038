import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  leaderboardCreate,
  leaderboardGetAll,
  leaderboardGet,
} from "../../../stores/actions/leaderBoardAction";

import { ProgressBar } from 'react-loader-spinner';

import pq from "../../../img/pq.jpeg";

import { Helmet } from "react-helmet";
const {BASE_URL } = require("../../../config/dev");


export default function Leaderboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lboard, setLboard] = useState(null);

  const findMyPosition = (leaderBoardAllData) => {
    const updatedLeaderboard = [...leaderBoardAllData];
    const sortedLeaderboard = updatedLeaderboard.sort(
      (a, b) => b.score - a.score
    );
    const updatedLeaderboardWithPositions = sortedLeaderboard.map(
      (entry, index) => ({
        ...entry,
        position: index + 1,
      })
    );
    return updatedLeaderboardWithPositions;
  };

  const { leaderboardData } = useSelector((state) => state.leaderBoard);

  useEffect(() => {
    dispatch(leaderboardGetAll());
  }, []);

  useEffect(() => {
    if (leaderboardData) {
      const updatedLeaderBoard = findMyPosition(leaderboardData);
      setLboard(updatedLeaderBoard);
    }
  }, [leaderboardData]);

  // useEffect(() => {
  //   document.getElementsByTagName("meta")["description"].content =
  //     "Check out My Score";
  //   document.getElementsByTagName("meta")["og:title"].content =
  //     "Planet Quizzer";
  //   document.getElementsByTagName("meta")["og:description"].content =
  //     "Quiz LeaderBoard";
  //   document.getElementsByTagName("meta")["og:image"].content = pq
  //     ? pq
  //     : "https://placehold.co/600x400/png";
  // }, [pq]);

  return (
    <div>
      <Helmet>
        <title>Planet Quizzer - leaderBoard</title>
        <meta
          name="description"
          content="Discover where you stand on the leaderboard. Compete with quiz enthusiasts, test your knowledge, and strive for the top spot. Join our quiz-loving community today!"
        />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta
          property="og:description"
          content="Discover where you stand on the leaderboard. Compete with quiz enthusiasts, test your knowledge, and strive for the top spot. Join our quiz-loving community today!"
        />
        <meta property="og:image" content={BASE_URL+"/"+"images/profilePic.png"}/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="image_src" href={BASE_URL+"/"+"images/profilePic.png"}></link>
      </Helmet>

      {lboard && lboard.length > 0 ? (
        <>
          <div className="container-fluid">
            {/* <header w3-include-html="header.html"></header> */}

            <div className="row  d-flex justify-content-center align-items-center leaderboard page-signup mt-4">
              <div className="col-sm-12 col-md-6">
                <h1 className="page-title">
                  All Time
                  <br />
                  <span className="blue">Top</span>
                  <span className="green">3 Scorers</span>
                </h1>
                <div className="row">
                  {lboard && lboard.length > 0
                    ? lboard.map((se, index) => {
                        if (index <= 2) {
                          let cn = `col-4 mb-4 mt-4 top-${se.position}`;
                          return (
                            <>
                              <div className={cn}>
                                <div className="avatar">
                                  <div className="picture-after">
                                    {/* <img className="crown" alt='' src={se.photo || "./images/crown.svg"} /> */}
                                    <img
                                      alt=""
                                      src={se.photo || "./images/avatar.png"}
                                    />
                                  </div>
                                  <p className="name mt-2">{se.name}</p>
                                  <button className="trees">{se.score}</button>

                                  <div className="score">{se.position}</div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })
                    : null}
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <table className="table">
                  <tbody>
                    {lboard && lboard.length > 0
                      ? lboard.map((se, index) => {
                          // console.log(104, se, se.position);
                          return (
                            <>
                              <tr>
                                <th scope="row">{se.position}</th>
                                <td className="user-name-85">
                                  <img
                                    alt=""
                                    src={
                                      se.photo || "./images/profile-icon.svg"
                                    }
                                  />
                                </td>
                                <td className="w-50 player-name">{se.name}</td>
                                <td className="w-25 player-trees">
                                  <button className="trees">{se.score}</button>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>

            {/* <footer className="footer fixed-footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
          </div>
        </>
      ) : (
        
           <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                >
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{}}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#156EF0'
                        barColor='#019317'
                    />
                </div>
    
      )}
    </div>
  );
}
