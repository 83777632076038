import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../../stores/actions/authAction";
import { useSelector } from 'react-redux'
import {
    USER_REGISTER_FAIL, USER_REGISTER_SUCCESS, USER_SUCCESS_MESSAGE_CLEAR, USER_ERROR_MESSAGE_CLEAR,
    USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, SIGN_OUT, USER_SET_SUCCESS_OTP, USER_SET_OTP_CLEAR,
    USER_DATA_SET_SUCCESS, USER_DATA_CLEAR
} from '../../../stores/types/authType'

import {
    userRetry
} from "../../../stores/actions/authAction";

import { ProgressBar } from 'react-loader-spinner';

export default function SignIn() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);

    const { successMessage, error, authenticate, userOtp, userRData, myInfo } = useSelector(state => state.auth)

    let data = {
        email: '',
        password: '',
    }

    const [state, setState] = useState(data)

    const inputHendle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    // console.log(state)

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true);
        dispatch(userLogin(state));
    }

    /*------------------------------------------------------------------------------- */
    /* OTP Generate */
    function generateOTP() {
        const digits = "0123456789";
        let otp = "";

        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * digits.length);
            otp += digits[randomIndex];
        }

        return otp;
    }

    let genOtp = generateOTP();


    const RetryOtp = (email, otp) => {
        dispatch(userRetry({ email: email, otp: otp })).then(
            (Response) => {
                dispatch({
                    type: USER_SET_SUCCESS_OTP,
                    payload: {
                        userOtp: otp,
                    },
                });
            }
        );
    };

    /*------------------------------------------------------------------------------- */


    useEffect(() => {
        if (authenticate) {
            setTimeout(() => {
                navigate("/");
                window.location.reload();
            }, 5000);
        }


        if (error) {
            setLoader(false);


            Swal.fire({
                icon: 'error',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                text: error
            }).then((res) => {
                /*------------------------------------------------- */
                console.log(res.isConfirmed)
                if (res.isConfirmed) {
                    // console.log(genOtp)

                    if (error === 'Your account is inactive.') {


                        dispatch({
                            type: USER_DATA_SET_SUCCESS,
                            payload: {
                                userRData: userRData,
                            },
                        })

                        RetryOtp(userRData.email, genOtp)

                        dispatch({
                            type: USER_SET_SUCCESS_OTP,
                            payload: {
                                userOtp: genOtp,
                            },
                        });

                        navigate("/verify-code")
                    }
                }
                /*-------------------------------------------------- */
                dispatch({ type: USER_ERROR_MESSAGE_CLEAR })
            })


        }
    }, [successMessage, error])


    return (

        <div>

            <div className="container-fluid">
                {/* <header w3-include-html="header.html"></header> */}

                <div className="row d-flex justify-content-center align-items-center page-signup">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <h1 className="page-title mx-auto">
                            <span className="blue">Sign</span>
                            <span className="green">In</span>
                        </h1>

                        <form className="text-center" onSubmit={handleSubmit}
                            style={{ maxWidth: "384px", margin: "auto" }}>
                            <div className="form-floating mt-4 mb-4">
                                <input type="email" className="form-control" name="email"
                                    onChange={(e) => inputHendle(e)} id="email" placeholder="Enter email" />
                                <label htmlFor="email">Enter email</label>
                            </div>
                            <div className="form-floating mt-4 mb-4">
                                <input type="password" className="form-control" name="password"
                                    onChange={(e) => inputHendle(e)} id="password" placeholder="Enter password" />
                                <label htmlFor="password">Enter password</label>
                            </div>
                            <div style={{ cursor: 'pointer' }} className="form-check text-right">
                                <a href='/forgot-password'>Forgot Password</a>
                            </div>

                            <button className="btn mt-4 mb-4 green-btn-2" type='submit'>
                                <span>Sign In</span>
                            </button>

                            {/* <div className="social-icons">
                                <p className="mt-4 mb-4">
                                    <span>Sign In With</span>
                                </p>

                                 <div className="all-icons">
                                    <a className="icon" href="/#">
                                        <i className="fab fa-lg fa-facebook-f"></i>
                                    </a>
                                    <a className="icon" href="/#">
                                        <i className="fab fa-lg fa-instagram"></i>
                                    </a>
                                    <a className="icon" href="/#">
                                        <i className="fab fa-lg fa-google"></i>
                                    </a>
                                    <a className="icon" href="/#">
                                        <i className="fab fa-lg fa-linkedin-in"></i>
                                    </a>
                                </div> 
                            </div>*/}
                        </form>
                    </div>
                    <div className="col-sm-3"></div>
                </div>

                {/* <footer className="footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
            </div>

            {loader ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                >
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{}}
                        wrapperClass="progress-bar-wrapper"
                        borderColor="#156EF0"
                        barColor="#019317"
                    />
                </div>
            ) : null}
        </div>

    )
}
