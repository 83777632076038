import axios from 'axios'
import {
    USER_REGISTER_FAIL, USER_REGISTER_SUCCESS, USER_SUCCESS_MESSAGE_CLEAR, USER_ERROR_MESSAGE_CLEAR,
    USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, SIGN_OUT, CUR_USER_DATA_GET_SUCCESS, CUR_USER_DATA_GET_ERROR,
    CUR_USER_DATA_GET_CLEAR, USER_UPDATE_FAIL, USER_UPDATE_SUCCESS, USER_GET_FAIL, USER_GET_SUCCESS
} from '../types/authType'

let {SERVER_URI,BASE_URL} = require("../../../src/config/dev")
const url = SERVER_URI;

// const token = localStorage.getItem('authToken');

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}



export const userRegister = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"

        // const token = localStorage.getItem('authToken');

        try {
            // userAuth(token)
            const response = await axios.post(`${url}/user/create`, data)

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const getLoginUserData = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"

        // const token = localStorage.getItem('authToken');

        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'userId': data.userId,
                'url': `${url}/user/get-current-User`
            }
        }

        try {
            // userAuth(token)
            const response = await axios.get(`${url}/user/get-current-User`, config)

            dispatch({
                type: CUR_USER_DATA_GET_SUCCESS, 
                payload: {
                    curUserData: response.data.data
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: CUR_USER_DATA_GET_CLEAR,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const getAllUser = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        // const token = localStorage.getItem('authToken');

        const config = {
            headers: {
                'Content-Type': 'application/josn',
                // 'userId': data.userId,
                'url': `${url}/user/user-get`
            }
        }

        try {
            // userAuth(token)
            const response = await axios.get(`${url}/user/user-get`, config)

            dispatch({
                type: USER_GET_SUCCESS,
                payload: {
                    userRData: response.data.data
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const userVerifyOtp = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        // const token = localStorage.getItem('authToken');

        try {
            // userAuth(token)
            const response = await axios.put(`${url}/user/verify-otp`, data)

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const userRetry = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        // const token = localStorage.getItem('authToken');

        try {
            // userAuth(token)
            const response = await axios.post(`${url}/user/retry`, data)


        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const userLogin = (datas) => {
    return async (dispatch) => {
        try {
            // console.log(datas)
            const response = await axios.post(`${url}/user/user-login`, datas)
            // console.log(response.data)
            localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    token: response.data.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message;
            let loginErrData = error?.response?.data?.data;
            // console.log(230, loginErrData )
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: {
                    error: data,
                    data: loginErrData
                }
            })
        }
    }
}



export const userFPassword = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        // const token = localStorage.getItem('authToken');

        try {
            // userAuth(token)
            const response = await axios.post(`${url}/user/user-forgot-password`, data)

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const userRPassword = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        // const token = localStorage.getItem('authToken');

        try {
            // userAuth(token)
            const response = await axios.post(`${url}/user/user-password-reset`, data)

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const userUpdate = (data) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        // const token = localStorage.getItem('authToken');

        try {
            // userAuth(token)
            const response = await axios.put(`${url}/user/user-update`, data)
            // console.log("295", response)
            dispatch({
                type: USER_UPDATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message || ''
            // console.log(data)
            dispatch({
                type: USER_UPDATE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}
