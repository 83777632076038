import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
const {BASE_URL } = require("../../../config/dev");


export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(75, window.location.pathname);
    return () => {
      if (window.location.pathname === "/") {
        document.body.classList.remove("body2"); // Remove the "body2" class
      } else {
        document.body.classList.add("body2"); // Add the "body2" class
      }
    };
  }, [window.location.pathname]);

  return (
    <>
      <Helmet>
        <title>Planet Quizzer</title>
        <meta
          name="description"
          content="Welcome to Planet Quizzer! Explore our diverse collection of quizzes, challenge your knowledge, and engage with our quiz-loving community. Stay tuned for new challenges and exciting content. Join us on a journey of fun and learning today!"
        />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta
          property="og:description"
          content="Welcome to Planet Quizzer! Explore our diverse collection of quizzes, challenge your knowledge, and engage with our quiz-loving community. Stay tuned for new challenges and exciting content. Join us on a journey of fun and learning today!"
        />
        <meta property="og:image" content={BASE_URL+"/"+"images/profilePic.png"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="image_src" href={BASE_URL+"/"+"images/profilePic.png"}></link>
      </Helmet>

      <div>
        <div className="container-fluid">
          {/* <header w3-include-html="header.html"></header> */}

          <div className="row justify-content-md-center mb-3 text-center home-center">
            <div className="col-lg-7">
              <h1 className="home-heading">
                Where
                <span className="blue">answering quiz</span>
                helps
                <span className="blue"> save</span>
                the
                <span className="green">planet</span>
              </h1>
              <button
                className="btn green-btn-1"
                onClick={() => navigate("/quiz")}
              >
                <span>Daily Quiz</span>
              </button>
              {/* <p className="know-more">
                            <a href="/#">Know More</a>
                        </p> */}
            </div>
          </div>

          {/* <footer className="footer fixed-footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
        </div>
      </div>
    </>
  );
}
