import React from 'react'

export default function Footer() {
    return (

        <footer className="footer fixed-footer">
            <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
        </footer>
    )
}
