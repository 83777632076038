import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../logo.svg";
import logoSm from "../../../logo-sm.svg";
import menu from "../../../mobile-menu.svg";
import { SIGN_OUT } from "../../../stores/types/authType";

import { QuizGet } from "../../../stores/actions/quizAction";
import { QuestionGet } from "../../../stores/actions/questionAction";
import {
  leaderboardCreate,
  leaderboardGetAll,
  leaderboardGet,
} from "../../../stores/actions/leaderBoardAction";
import {
  submissionGet,
} from "../../../stores/actions/submissionAction";

import {
  getAllUser,
  getLoginUserData,
} from "../../../stores/actions/authAction";

import { NewsGet } from "../../../stores/actions/newsAction";



function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { successMessage, error, curUserData, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { leaderboardData } = useSelector((state) => state.leaderBoard);

  const [photo, setPhoto] = useState("");

  useEffect(() => {
    dispatch(getLoginUserData({ userId: myInfo.Id }));
  }, [myInfo && myInfo.Id && myInfo.Id.length > 0]);

  // console.log(41,curUserData);

  useEffect(() => {
    if (curUserData && curUserData.photo) {
      setPhoto(curUserData.photo);
    } else {
      setPhoto("../../images/profile.svg");
    }
  }, [curUserData]);

  /** some function call */
  useEffect(() => {
    dispatch(QuizGet());
    // dispatch(QuestionGet());
    dispatch(leaderboardGetAll());
    dispatch(NewsGet())
    dispatch(getAllUser());
  }, []);

  useEffect(() => {
    if (myInfo && myInfo.Id) {
      dispatch(submissionGet(myInfo.Id));
    }
  }, [myInfo]);

  const [cuPage, setCuPage] = useState("");
  // navigate('/quiz') navigate('/extra-quiz') navigate('/leaderboard') navigate('/news')

  const handelPage = (root) => {
    if (authenticate) {
      setCuPage(root);
      navigate(`/${root}`);
    } else if (root === "news") {
      navigate(`/news`);
    } else {
      navigate(`/login`);
    }
  };

  useEffect(()=>{
    // console.log(window.location.pathname)
    if(window.location.pathname === "/quiz"){
      setCuPage("quiz");
    }else if(window.location.pathname === "/extra-quiz"){
      setCuPage("extra-quiz")
    }else if(window.location.pathname === "/leaderboard"){
      setCuPage("leaderboard")
    }
  },[window.location.pathname])

  const logOut = () => {
    localStorage.clear();
    dispatch({ type: SIGN_OUT });
    navigate('/');
    window.location.reload();
  };

  // console.log(myInfo, leaderboardData);

  // useEffect(() => {
  //   if(myInfo && myInfo.Id){
  //         leaderboardData.map((sl) => {
  //     if (myInfo.Id === sl.userId) {
  //       setPhoto(sl.photo);
  //     }
  //   });
  //   }
  // }, [leaderboardData || myInfo]);



  return (
    <div className="container-fluid">
      <div className="row">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand col-md-2" href="/">
            <img
              alt=""
              className="bi me-2 hide-on-mobile"
              width="150"
              role="img"
              aria-label="Bootstrap"
              src={logo}
            />

            <img
              alt=""
              className="bi me-2 show-on-mobile"
              role="img"
              aria-label="Bootstrap"
              src={logoSm}
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <!-- <span className = "navbar-toggler-icon"></span> --> */}
            <img alt="" className="bi me-2" width="150" role="img" src={menu} />
          </button>

          <div
            className="collapse navbar-collapse col-md-6 col-md-auto mb-2 justify-content-center mb-md-0"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav justify-content-center">
              <li
                className={cuPage === "quiz" ? "nav-item active" : "nav-item"}
                style={{ cursor: "pointer" }}
              >
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => handelPage("quiz")}
                >
                  Play Daily Quiz
                </a>
              </li>

              <li
                className={
                  cuPage === "extra-quiz" ? "nav-item active" : "nav-item"
                }
                style={{ cursor: "pointer" }}
              >
                <a
                  className="nav-link"
                  onClick={() => handelPage("extra-quiz")}
                >
                  Play Extra Quiz
                </a>
              </li>

              <li
                className={
                  cuPage === "leaderboard" ? "nav-item active" : "nav-item"
                }
                style={{ cursor: "pointer" }}
              >
                <a
                  className="nav-link"
                  onClick={() => handelPage("leaderboard")}
                >
                  Leaderboard
                </a>
              </li>

              <li
                className={cuPage === "news" ? "nav-item active" : "nav-item"}
                style={{ cursor: "pointer" }}
              >
                <a className="nav-link" onClick={() => handelPage("news")}>
                  News
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 text-end dropdown">
            {/* {authenticate && myInfo && myInfo.fname ?
                        <a className="navbar-brand notification-bell" role="button" aria-hidden="false" href="/#">
                            <img alt='' className="bi" width="15" role="img" src="../../images/notification-bell.svg" />
                            <span className="badge rounded-pill bg-primary">
                                4
                            </span>
                        </a>
                        :
                        null
                    } */}

            {authenticate && myInfo && myInfo.fname ? (
              <button
                type="button"
                className="btn profile"
                onClick={() => navigate("/my-account")}
              >
                <img
                  alt=""
                  className="user-78"
                  width="15"
                  role="img"
                  src={photo || "../../images/profile.svg"}
                />
                <span>{myInfo ? `${myInfo.fname ? myInfo.fname : ""} ${myInfo.lname ? myInfo.lname : ""}` : ""}</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={() => navigate("/signup")}
                className="btn signout"
              >
                <span> Sign Up</span>
              </button>
            )}

            <span className="separator">|</span>

            {authenticate && myInfo && myInfo.fname ? (
              <button type="button" onClick={logOut} className="btn signout">
                <span> Sign Out</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={() => navigate("/login")}
                className="btn signout "
              >
                <span> Login</span>
              </button>
            )}
          </div>

          <ul className="dropdown-menu">
            <li className="divider">
              <a href="/#">Mark as read</a>
            </li>
            <li>
              <a className="dropdown-item active" href="/#">
                You have completed the daily quiz
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/#">
                You have completed the daily quiz
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/#">
                Something else here You have completed the daily quiz
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/#">
                Separated link You have completed the daily quiz
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
