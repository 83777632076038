import axios from "axios";
import {
    COMMENT_GET_SUCCESS, COMMENT_GET_FAIL, COMMENT_CREATE_SUCCESS, COMMENT_CREATE_FAIL,
    COMMENT_DELETE_SUCCESS, COMMENT_DELETE_FAIL
}
    from '../types/commentType'


    let {SERVER_URI,BASE_URL} = require("../../../src/config/dev")
    const url = SERVER_URI;



export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}



export const CommentCreate = (datas) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        const token = localStorage.getItem("authToken");

        // console.log("27" ,datas)

        try {
            userAuth(token)
            const response = await axios.post(`${url}/user/create-comment`, datas)
            // console.log(response.data.data)

            dispatch({
                type: COMMENT_CREATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                }
            })
        }

        catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: COMMENT_CREATE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



export const CommentGet = (datas) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'url': `${url}/user/get-comment`,
                'newsId': datas.newsId
            }
        }
        // const token = localStorage.getItem("authToken");
        try {
            // console.log(datas)
            // userAuth(token)
            const response = await axios.get(`${url}/user/get-comment`, config)
            // console.log(response.data.data)

            dispatch({
                type: COMMENT_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    commentData: response.data.data
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: COMMENT_DELETE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}




export const CommentDelete = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"



        const config = {
            headers: {
                'Content-Type': 'application/josn',
                // 'adminId': datas.adminId,
                'url': `${url}/user/delete-comment`,
                'articalId': datas._id
            }
        }

        const token = localStorage.getItem("authToken");

        try {
            // console.log(config)
            userAuth(token)
            const response = await axios.delete(`${url}/user/delete-comment`, config)
            // console.log(response.data.data)

            dispatch({
                type: COMMENT_DELETE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: COMMENT_DELETE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}