import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { QuizGet } from "../../../stores/actions/quizAction";
import { QuestionGet } from "../../../stores/actions/questionAction";
import {
  submissionCreate,
  submissionGet,
} from "../../../stores/actions/submissionAction";

import { ProgressBar } from "react-loader-spinner";

import { Helmet } from "react-helmet";

import { QUESTION_GET_SUCCESS_CLEAR } from "../../../stores/types/questionType"

const { BASE_URL } = require("../../../config/dev");





export default function Quiz() {
  const { dailyQuizId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { quizData, quizError, quizSuccessMessage, quizSuccessMessagee } =
    useSelector((state) => state.quiz);

  const {
    questionData,
    questionError,
    questionSuccessMessage,
    questionSuccessMessagee,
  } = useSelector((state) => state.question);

  const {
    submissionData,
    submissionError,
    submissionSuccessMessage,
    submissionSuccessMessagee,
  } = useSelector((state) => state.submission);

  const { myInfo } = useSelector((state) => state.auth);

  const [newQuiz, setNewQuiz] = useState(null);

  const [userAnswers, setUserAnswers] = useState(null);
  const [userResponses, setUserResponses] = useState([]);

  const [timer, setTimer] = useState(null);
  const [stopTime, setStopTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [startTime, setStartTime] = useState(null);

  const [call, setCall] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedButtonId, setSelectedButtonId] = useState(null);

  const [loaders, setLoaders] = useState(false);

  const [curQuestions, setcurQuestions] = useState({});
  const [curIndex, setcurIndex] = useState(0);


  // console.log(76,submissionData);

  /** Conditipon For Playing Daily QUIZ */
  useEffect(() => {

    const ArrayOfTodayQuiz = [];
    let date = new Date();
    date = date.toISOString().split("T")[0];
    if (quizData) {
      quizData?.map((sq) => {
        let x = sq?.liveDate || "";
        x = x.split("T")[0];

        if (x == date) {
          ArrayOfTodayQuiz.push(sq._id);
        }
      });
    }

    // console.log(86,"Today all quiz", ArrayOfTodayQuiz);

    /* if no quiz in today quiz array */
    if (ArrayOfTodayQuiz.length === 0) {
      navigate(`/quiz-waiting/000`);
    }

    /* setting daily quiz */
    localStorage.setItem("DailyQuizId", ArrayOfTodayQuiz[0]);
    setNewQuiz(ArrayOfTodayQuiz[0]);



    const AllAttemptedUniqueQuizIds = [
      ...new Set(submissionData.map((item) => item.quizId)),
    ];
    // console.log(102,"All Submitted Quiz Id==>", AllAttemptedUniqueQuizIds);

    /* checking if he already played daily quiz or not */
    const AttemptedDailyQuizIds = ArrayOfTodayQuiz.filter((element) =>
      AllAttemptedUniqueQuizIds.includes(element)
    );
    // console.log(107,"You played Today's Quize ==>", AttemptedDailyQuizIds,  ArrayOfTodayQuiz[0]);

    const unplayedQuizIds = ArrayOfTodayQuiz.filter(
      (element) => !AllAttemptedUniqueQuizIds.includes(element)
    );

    // console.log("unplayed Quize ==>", unplayedQuizIds);

    const randomIndex = Math.floor(Math.random() * unplayedQuizIds.length);

    // Get the random quiz ID
    // const randomQuizId = unplayedQuizIds[randomIndex];
    // console.log(randomQuizId);

    if (AttemptedDailyQuizIds.length > 0) {
      // console.log("You have play Extra Quize:::: Random Quiz Ids:" + randomQuizId);
      // localStorage.setItem("ExtraQuizIds", randomQuizId);
      // // navigate("/quiz-complete")

      if (unplayedQuizIds.length > 0) {
        // Get the random quiz ID
        const randomQuizId = unplayedQuizIds[randomIndex];
        // console.log(randomQuizId);

        // console.log("You have play Extra Quize:::: Random Quiz Ids:" + randomQuizId);
        localStorage.setItem("ExtraQuizIds", randomQuizId);

        let DailyQuizId = localStorage.getItem("DailyQuizId");
        navigate(`/quiz-complete/${DailyQuizId}`);
      } else if (unplayedQuizIds.length == 0) {
        let DailyQuizId = localStorage.getItem("DailyQuizId");
        navigate(`/quiz-waiting/${DailyQuizId}`);
      }
    } else {
      // console.log("Your Dily QuizId id id  ====>" + ArrayOfTodayQuiz[0])
      // navigate(`/quiz-waiting/${ArrayOfTodayQuiz[0]}`)
    }
  }, [quizData || submissionData.length]);

  

  /* Fetching Question Against Quiz Id */
  useEffect(() => {
    if (newQuiz && newQuiz.length > 23) {
      dispatch(QuestionGet(newQuiz));
    }
  }, [newQuiz]);

  /** Setting First Question */
  useEffect(() => {
    if (questionData && questionData.length > 0 && Object.keys(curQuestions).length === 0) {
      setcurQuestions(questionData[0]);
      setcurIndex(0);
    }
  }, [questionData]);


  const handleOptionClick = (selectedOption, selectedButtonId) => {
    // Remove "select" class from previously selected button
    if (selectedButtonId) {
      let previousSelectedButton = document.getElementById(selectedButtonId);
      previousSelectedButton.classList.remove("select");
    }

    // Add "select" class to the clicked button
    let selectedButton = document.getElementById(selectedOption);
    selectedButton.classList.add("select");

    // Update selected option and button ID
    setSelectedOption(selectedOption);
    // setSelectedButtonId(selectedOption);
    setUserAnswers(selectedOption);
  };


  /****************************ADVERTISE SECTION***********************************/
  const [advState, setAdvState] = useState(false);

  const [advCloseBtn, setAdvCloseBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    setAdvState(false);
    startTimer();
  };

  const openModal = () => {
    console.log("open modal")
    onOpenModal(true);
    setAdvCloseBtn(false);
  };



  useEffect(() => {
    const index = Number(curIndex) + 1;
    if (index === 3 || index === 5 || index === 7) {
      console.log("show advertisement");
      setAdvState(true);
      openModal();
    }
  }, [curIndex])


  useEffect(()=>{
    // console.log(219,advState,open);
    if(advState && open){
      setTimeout(() => {
      setAdvCloseBtn(true);
    }, 10000);
    }
  },[advState])





  /***********************************SUBMISISSION CONTROLLERS*********************************************/

  /* timer */
  const startTimer = () => {
    const currentTime = Date.now();
    const endTime = currentTime + curQuestions.time * 1000;

    setStartTime(currentTime);
    setStopTime(endTime);
    setTimeLeft(curQuestions.time);

    const timerId = setInterval(() => {
      const currentTime = Date.now();
      const remainingTime = Math.max(
        0,
        Math.floor((endTime - currentTime) / 1000)
      );

      // Handle time's up
      if (remainingTime === 0) {
        clearInterval(timerId);
      }

      setTimeLeft(remainingTime);
    }, 1000);

    setTimer(timerId);
  }


  /*timer according to question timer */
  useEffect(() => {
    if (curQuestions?.time && !advState) {
      startTimer();
    }
  }, [curQuestions]);

  /* Timer specific submission and Next */
  console.log(254,"advState ouside ue(timer)", advState);

  useEffect(() => {
    
    const index = Number(curIndex) + 1;

    console.log(258,"advState inside ue(timer)", advState);

    if (advState) {
      console.log("here we stop timer");
      clearInterval(timer);
      // setAdvState(true);
    }

    if (timeLeft === 0 && index < questionData.length) {
      clearInterval(timer);
      // console.log("your answer: " + userAnswers)

      /* fetching time taken */
      const endTime = Date.now();
      const timeTaken = (endTime - startTime) / 1000;
      clearInterval(timer);

      /* setting data */
      const data = {
        participant: myInfo?.Id || null,
        questionId: curQuestions?._id || null,
        quizId: newQuiz,
        questionTitle: curQuestions?.qusTitle || null,
        userAnswer: userAnswers,
        options: curQuestions?.options || null,
        correctAnswer: curQuestions?.correctAnswer || null,
        responsesTime: `${timeTaken}`,
      };

      setUserResponses([...userResponses, data]);

      /* Jumping to next question */
      setcurQuestions(questionData[index]);
      setcurIndex(index);

      /* resetiing answer state to "" */
      setUserAnswers("");
    }
    if (timeLeft === 0 && index === questionData.length) {
      clearInterval(timer);

      /* fetching time taken */
      const endTime = Date.now();
      const timeTaken = (endTime - startTime) / 1000;
      clearInterval(timer);

      /* setting data */
      const data = {
        participant: myInfo?.Id || null,
        questionId: curQuestions?._id || null,
        quizId: newQuiz,
        questionTitle: curQuestions?.qusTitle || null,
        userAnswer: userAnswers,
        options: curQuestions?.options || null,
        correctAnswer: curQuestions?.correctAnswer || null,
        responsesTime: `${timeTaken}`,
      };

      setUserResponses([...userResponses, data]);

      setCall(true);
    }
  }, [timeLeft]);


  /* Button specific submission and Next */
  const NextQustion = (index) => {

    if (selectedButtonId) {
      let previousSelectedButton = document.getElementById(selectedButtonId);
      previousSelectedButton.classList.remove("select");
    }

    setSelectedOption(null);
    setSelectedButtonId(null);

    if (userAnswers !== null && index <= questionData.length) {
      setLoaders(true);
      clearInterval(timer);

      // console.log(curQuestions.correctAnswer)

      if (curQuestions.correctAnswer === userAnswers) {

        // Handle the correct answer: update className of correct option button
        let correctButton = document.getElementById(userAnswers);

        if (correctButton) {
          correctButton.classList.add("correct");
        }

        /* fetching time taken */
        const endTime = Date.now();
        const timeTaken = (endTime - startTime) / 1000;
        clearInterval(timer);

        /* setting data */
        const data = {
          participant: myInfo?.Id || null,
          questionId: curQuestions?._id || null,
          quizId: newQuiz,
          questionTitle: curQuestions?.qusTitle || null,
          userAnswer: userAnswers,
          options: curQuestions?.options || null,
          correctAnswer: curQuestions?.correctAnswer || null,
          responsesTime: `${timeTaken}`,
        };

        setUserResponses([...userResponses, data]);

        // console.log(303, userResponses)
        setTimeout(() => {
          /* Jumping to next question */
          if (index !== questionData.length) {
            // setUserAnswers("")
            setUserAnswers(null);
            correctButton.classList.remove("correct");
            setcurQuestions(questionData[index]);
            setcurIndex(index);
            setLoaders(false)
          }
        }, 1000);
      } else if (curQuestions.correctAnswer !== userAnswers) {

        // // Handle the incorrect answer: update className of selected option button
        let selectedButton = document.getElementById(userAnswers);
        if (selectedButton) {
          selectedButton.classList.add("incorrect");
        }

        // // Handle the correct answer: update className of correct option button
        let correctButton = document.getElementById(curQuestions.correctAnswer);
        if (correctButton) {
          correctButton.classList.add("correct");
        }

        /* fetching time taken */
        const endTime = Date.now();
        const timeTaken = (endTime - startTime) / 1000;
        // clearInterval(timer);
        // console.log(`Time taken: ${timeTaken} seconds`);

        /* setting data */
        const data = {
          participant: myInfo?.Id || null,
          questionId: curQuestions?._id || null,
          quizId: newQuiz,
          questionTitle: curQuestions?.qusTitle || null,
          userAnswer: userAnswers,
          options: curQuestions?.options || null,
          correctAnswer: curQuestions?.correctAnswer || null,
          responsesTime: `${timeTaken}`,
        };

        setUserResponses([...userResponses, data]);

        // console.log(index, userResponses);

        setTimeout(() => {
          setUserAnswers(null);
          // selectedButton.classList.remove("incorrect");
          // correctButton.classList.remove("correct");
          /* Jumping to next question */
          if (index !== questionData.length) {
            setUserAnswers("");
            selectedButton.classList.remove("incorrect");

            correctButton.classList.remove("correct");

            setcurQuestions(questionData[index]);
            setcurIndex(index);
          }

          if (index === questionData.length) {
            // console.log("Question no: 8::::::::DB CALL")
            // dispatch(submissionCreate(userResponses))
            setCall(true);
          }
          setLoaders(false)
        }, 1000);
      }

      if (index === questionData.length) {
        // console.log("Question no: 8::::::::DB CALL")
        setCall(true);
      }
    }
  };

  /* Submission Call */
  useEffect(() => {
    if (call) {
      clearInterval(timer);
      dispatch(submissionCreate(userResponses)).then(() => {
        setUserResponses("");
        dispatch({
          type: QUESTION_GET_SUCCESS_CLEAR
        });
        setCall(false);
        let DailyQuizId = localStorage.getItem("DailyQuizId");
        navigate(`/quiz-complete/${DailyQuizId}`);
      });
    }
  }, [call]);

  useEffect(() => {
    setCall(false);
  }, []);


  return (
    <>
      <Helmet>
        <title>Planet Quizzer - Quiz</title>
        <meta
          name="description"
          content="Welcome to our quiz website's news page! Dive into a world of knowledge, entertainment, and discovery. Stay up-to-date with the latest quiz trends and news. Explore captivating quizzes, thrilling trivia, and thought-provoking articles, all in one place. Challenge yourself, discover new facts, and immerse yourself in the exciting world of quizzes. Join us on this journey of fun and learning today!"
        />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta
          property="og:description"
          content="Welcome to our quiz website's news page! Dive into a world of knowledge, entertainment, and discovery. Stay up-to-date with the latest quiz trends and news. Explore captivating quizzes, thrilling trivia, and thought-provoking articles, all in one place. Challenge yourself, discover new facts, and immerse yourself in the exciting world of quizzes. Join us on this journey of fun and learning today!"
        />
        <meta
          property="og:image"
          content={BASE_URL + "/" + "images/profilePic.png"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link
          rel="image_src"
          href={BASE_URL + "/" + "images/profilePic.png"}
        ></link>
      </Helmet>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignCtems: "center",
          height: "100vh",
        }}
      >

        <div width="75%" height="50%">
          <Modal open={open} onClose={onCloseModal} showCloseIcon={advCloseBtn} closeOnEsc={false} closeOnOverlayClick={false} center>
            <div className="App" style={{margin:"3%"}}>
              <video width="100%" height="80%"  controls >
                <source src="https://www.taxmann.com/emailer/images/FEMA.mp4" type="video/mp4" />
              </video>
            </div>
          </Modal>
        </div>


        {questionData && questionData.length > 0 ? (
          <>
            <div className="container-fluid">


              <div className="row " >
                <div className="col-lg-12" style={{ backgroundColor: "grey", height: "90px" }}>
                  {/* <div className="top-bar border" >Top</div> */}
                  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                  <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="your-ad-client-id"
                    data-ad-slot="your-ad-slot-id"
                    data-ad-format="auto"
                  ></ins>
                  <script>
                    (adsbygoogle = window.adsbygoogle || []).push({ });
                  </script>
                </div>
                <div className="col-lg-3 border" style={{ backgroundColor: "grey" }}>
                  {/* <div className="left-bar-one" >Left</div> */}
                </div>



                <div className="col-lg-6 border">

                  <div className="row d-flex justify-content-center align-items-center page-signup">
                    {/* <div className="col-md-2"></div> */}

                    <div className="col-md-12">
                      <form id="regForm" action="/action_page.php">
                        {/* <!-- Circles which indicates the steps of the form: --> */}
                        <div className="all-steps">
                          {questionData && questionData.length > 0
                            ? questionData.map((_, index) => (
                              <span
                                className={`step ${index === 0 ? "first-child" : ""
                                  } ${index === questionData.length - 1
                                    ? "last-child"
                                    : ""
                                  }`}
                                key={index}
                              >
                                {index === curIndex &&
                                  curIndex !== 0 &&
                                  curIndex !== questionData.length - 1 ? (
                                  <span
                                    style={{
                                      backgroundColor: "#019317",
                                      borderRadius: "50%",
                                      height: "15px",
                                      width: "15px",
                                      display: "block",
                                      position: "absolute",
                                    }}
                                  ></span>
                                ) : index === 0 ||
                                  index === questionData.length - 1 ? (
                                  <span>{`0${index + 1}`}</span>
                                ) : null}
                              </span>
                            ))
                            : null}
                        </div>

                        <div className="tab quiz">
                          <p className="time-left">
                            Time Left:{" "}
                            {timeLeft !== null
                              ? `${Math.floor(timeLeft / 60)}:${timeLeft % 60}`
                              : "00:00"}
                          </p>

                          <h1 className="question">
                            <span>{curQuestions?.qusTitle || ""}</span>
                          </h1>

                          {/* correct incorrect options */}
                          <div className="options">
                            {curQuestions &&
                              curQuestions.options &&
                              curQuestions.options.length > 0
                              ? curQuestions?.options.map((value, index) => {
                                return (
                                  <button
                                    type="button"
                                    className=""
                                    key={index}
                                    id={value}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      gap: "5%",
                                      backgroundColor:
                                        selectedOption === value
                                          ? "grey"
                                          : "#D3E7FF",
                                      color:
                                        selectedOption === value
                                          ? "white"
                                          : "initial",
                                    }}
                                    onClick={() =>
                                      handleOptionClick(value, selectedButtonId)
                                    }
                                  >
                                    <i
                                      className="fa-solid fa-circle"
                                      style={{ marginTop: "4%" }}
                                    ></i>
                                    {value}
                                  </button>
                                );
                              })
                              : null}
                          </div>

                          <div className="submit-answer">
                            <button
                              type="button"
                              onClick={() => NextQustion(Number(curIndex) + 1)}
                              className=""
                              style={{ width: '200px' }}
                            >
                              {loaders ? (
                                <i className="fa fa-spinner fa-spin"></i>
                              ) : null}
                              &nbsp;&nbsp; Submit Answer
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col-md-2"></div>
                  </div>

                </div>



                <div className="col-lg-3 border" style={{ backgroundColor: "grey" }}>
                  {/* <div className="left-bar-one " >Right</div> */}
                </div>
                <div className="col-lg-12 border" style={{ backgroundColor: "grey", height: "90px" }}>
                  {/* <div className="buttom-bar-one " >Button</div> */}
                </div>
              </div>



              {/* <footer className="footer fixed-footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}

            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#156EF0"
              barColor="#019317"
            />
          </div>
        )}

      </div>
    </>
  );
}
