import {
    USER_REGISTER_FAIL, USER_REGISTER_SUCCESS, USER_SUCCESS_MESSAGE_CLEAR, USER_ERROR_MESSAGE_CLEAR,
    USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, SIGN_OUT, USER_SET_SUCCESS_OTP, USER_SET_OTP_CLEAR,
    USER_DATA_SET_SUCCESS, USER_DATA_CLEAR, CUR_USER_DATA_GET_SUCCESS, CUR_USER_DATA_GET_ERROR,
    CUR_USER_DATA_GET_CLEAR, USER_UPDATE_FAIL, USER_UPDATE_SUCCESS, USER_GET_SUCCESS, USER_GET_FAIL
} from '../types/authType'
import deCodeToken from 'jwt-decode'

const authState = {
    loading: true,
    authenticate: false,
    error: '',
    token: '',
    userOtp: '',
    successMessage: '',
    curUserData: {},
    userRData: {},
    myInfo: {}
}



const tokenDecode = (token) => {
    const tokenDecoded = deCodeToken(token);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
        return null
    }
    return tokenDecoded;
}

const getToken = localStorage.getItem('authToken');
if (getToken && getToken.length > 10) {
    const getInfo = tokenDecode(getToken)
    // console.log(getInfo)
    if (getInfo) {
        authState.myInfo = getInfo;
        authState.authenticate = true;
        authState.loading = false
    }
}


export const authReducer = (state = authState, action) => {
    const { payload, type } = action;
    if (type === USER_REGISTER_FAIL) {
        return {
            ...state,
            error: payload.error,
            // authenticate: false,
            // loading: true,
            myInfo: ''
        }
    }
    if (type === USER_LOGIN_FAIL) {
        console.log(56,payload.data)
        return {
            ...state,
            error: payload.error,
            userRData: payload.data
        }
    }

    if (type === USER_REGISTER_SUCCESS) {
        // console.log(payload)
        // const myInfo = tokenDecode(payload.token);

        return {
            ...state,
            successMessage: payload.successMessage,
            error: '',
            loading: false
        }
    }

    if (type === USER_LOGIN_SUCCESS) {
        return { 
            ...state,
            successMessage: payload.successMessage,
            error: '',
            authenticate: true,
            loading: false,
            token: payload.token
        }
    }

    if (type === USER_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: ''
        }
    }
    if (type === USER_ERROR_MESSAGE_CLEAR) {
        return {
            ...state,
            error: ''
        }
    }
    if (type === USER_SET_SUCCESS_OTP) {
        console.log(91,payload.userOtp)
        return {
            ...state,
            userOtp: payload.userOtp
        }
    }
    if (type === USER_SET_OTP_CLEAR) {
        return {
            ...state,
            userOtp: ''
        }
    }
    if (type === USER_DATA_SET_SUCCESS) {
        return {
            ...state,
            userRData: payload.userRData
        }
    }
    if (type === USER_DATA_CLEAR) {
        return {
            ...state,
            userRData: {}
        }
    }
    if(type === CUR_USER_DATA_GET_SUCCESS){
        return{
            ...state,
            curUserData: payload.curUserData
        }
    }
    if(type === CUR_USER_DATA_GET_CLEAR){
        return{
            ...state,
            curUserData: {}
        }
    }
    if(type === USER_UPDATE_FAIL){
        return{
            ...state,
            error: payload.error
        }
    }
    if(type === USER_UPDATE_SUCCESS){
        return{
            ...state,
            successMessage: payload.successMessage,
        }
    }
    if(type ===USER_GET_SUCCESS){
        return{
            ...state,
            userRData: payload.userRData,
            successMessage: payload.successMessage
        }
    }
    if(type === USER_GET_FAIL){
        return{
            ...state,
            error: payload.error
        }
    }
    // if (type === SIGN_OUT) {
    //     return {
    //         ...state,
    //         loading: true,
    //         authenticate: false,
    //         error: '',
    //         token: '',
    //         userOtp: '',
    //         successMessage: '',
    //         userRData: {},
    //         myInfo: {}
    //     }
    // }
    return state;
}