import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NewsGet } from "../../../stores/actions/newsAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ShareButtons, ShareCounts, generateShareIcon } from "react-share";

import { ProgressBar } from "react-loader-spinner";

import { Helmet } from "react-helmet";
const {BASE_URL } = require("../../../config/dev");


function News() {
  localStorage.removeItem("newsId");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    articalData,
    articalError,
    articalSuccessMessage,
    articalSuccessMessagee,
  } = useSelector((state) => state.news);

  // console.log(18, articalData);

  // useEffect(() => {
  //   dispatch(NewsGet());
  // }, []);

  const handelNews = (data) => {
    localStorage.setItem("newsId", data._id);
    let Id = data.headingName
      .split(" ")
      .map((value) => {
        return value.trim().toLowerCase();
      })
      .join("-");
    localStorage.setItem("newsPath", Id);
    navigate(`/news-details/${Id}`);
  };


  function slugify(str) {
    return String(str)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
  }

  return (
    <>
      <Helmet>
        <title>Planet Quizzer - News</title>
        <meta name="description" content="Stay informed with the latest quiz trends and news! Explore captivating quizzes, exciting trivia, and insightful articles on our quiz website's news page. Test your knowledge, discover new facts, and stay ahead in the world of quizzes. Dive into a world of fun and learning – all in one place!" />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta property="og:description" content="Stay informed with the latest quiz trends and news! Explore captivating quizzes, exciting trivia, and insightful articles on our quiz website's news page. Test your knowledge, discover new facts, and stay ahead in the world of quizzes. Dive into a world of fun and learning – all in one place!" />
        <meta
          property="og:image"
          content={BASE_URL+"/"+"images/profilePic.png" || "https://placehold.co/600x400/png"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="image_src" href={BASE_URL+"/"+"images/profilePic.png" || "https://placehold.co/600x400/png"}></link>
      </Helmet>

      <div className="news-detail">
        {articalData && articalData.length > 0 ? (
          <div className="container-fluid">
            {/* <header w3-include-html="header.html"></header> */}

            <div className="row">
              <div className="col-sm-12">
                <h1 className="page-title mx-auto">
                  <span className="blue">News</span>
                  <span className="green">Point</span>
                </h1>

                <div className="row">
                  {articalData && articalData.length > 0
                    ? articalData.map((value, index) => {
                        return (
                          <div
                            className="col-sm-6 col-md-4 mb-4 mt-4 d-flex align-items-stretch news-item"
                            style={{ position: "relative" }}
                            key={index}
                          >
                            <div
                              className={
                                index % 2 === 0
                                  ? "card bg-green"
                                  : "card bg-blue"
                              }
                              onClick={() => handelNews(value)}
                            >
                              <div className="card-header">
                                <span className="card-number">{`0${
                                  index + 1
                                }`}</span>

                                {/* Sharing option*/}
                                <div className="all-icons">
                                  <a
                                    className="icon"
                                    // onClick={() => shareFacebook(value)}
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${
                                      window.location.protocol
                                    }//${
                                      window.location.hostname
                                    }/news-details/${slugify(
                                      value.headingName
                                    )}`}
                                  >
                                    <i className="fab fa-lg fa-facebook-f"></i>
                                  </a>

                                  <a
                                    className="icon"
                                    href={`https://www.linkedin.com/shareArticle?url=${
                                      window.location.protocol
                                    }//${
                                      window.location.hostname
                                    }/news-details/${slugify(
                                      value.headingName
                                    )}&title=${value.headingName}&summary=${
                                      value.headingName
                                    }&source=${window.location.href}`}
                                  >
                                    <i className="fab fa-lg fa-linkedin-in"></i>
                                  </a>

                                </div>

                              </div>

                              <div className="pro-picture">
                                <img
                                  src={
                                    value && value.image
                                      ? value.image
                                      : "./images/news.png"
                                  }
                                  className="card-img-top"
                                  alt="..."
                                />
                              </div>

                              <div className="card-body">
                                <h5 className="card-title">
                                  {value.headingName}
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#156EF0"
              barColor="#019317"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default News;
