import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../../../stores/actions/authAction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import {
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
  USER_SUCCESS_MESSAGE_CLEAR,
  USER_ERROR_MESSAGE_CLEAR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  SIGN_OUT,
  USER_SET_SUCCESS_OTP,
  USER_SET_OTP_CLEAR,
  USER_DATA_SET_SUCCESS,
  USER_DATA_CLEAR,
} from "../../../stores/types/authType";

import { ProgressBar } from "react-loader-spinner";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const { successMessage, error, authenticate, userOtp, userRData, myInfo } =
    useSelector((state) => state.auth);

  function generateOTP() {
    const digits = "0123456789";
    let otp = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * digits.length);
      otp += digits[randomIndex];
    }

    return otp;
  }

  let obj = {
    name: "",
    email: "",
    password: "",
    ReEnterPassword: "",
    checkbox: false,
    otp: generateOTP(),
  };
  const [state, setState] = useState(obj);

  const handelCreate = (e) => {
    // console.log(e.target.checked);
    if (e.target.name === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  // console.log(state)

  const handelSubmit = async () => {
    let data = {
      fname: `${state.name.split(" ")[0]}`,
      lname:
        state.name.split(" ")[1] != undefined
          ? `${state.name.split(" ")[1]}`
          : "",
      phoneNumber: "",
      email: state.email,
      password: state.password,
      status: "Inactive",
      role: "User",
      otp: state.otp,
    };
    if(!data.fname || !data.email || !data.password){
      Swal.fire({
        icon: "error",
        // //title: "Oops...",
        text: "All fields are required",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;
    }

    if (
      state.password === "" ||
      state.ReEnterPassword === "" ||
      state.password !== state.ReEnterPassword
    ) {
      Swal.fire({
        icon: "error",
        //title: "Oops...",
        text: "Password and Confirm Password not same",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;
    }
    if (state.checkbox === false) {
      Swal.fire({
        icon: "error",
        //title: "Oops...",
        text: "Please accept the term and conditions",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;
    }
    
    // console.log(data)
    dispatch(userRegister(data));
    // console.log("save data to user register")
    setLoader(true);
  };

  /* Sweet alert for sign Up */
  useEffect(() => {
    if (successMessage) {
      
      let data = {
        fname: `${state.name.split(" ")[0]}`,
        lname:
          state.name.split(" ")[1] != undefined
            ? `${state.name.split(" ")[1]}`
            : "",
        phoneNumber: "",
        email: state.email,
        status: "Inactive",
        role: "User",
      };

      dispatch({
        type: USER_DATA_SET_SUCCESS,
        payload: {
          userRData: data,
        },
      });
      setState(obj);
      dispatch({
        type: USER_SET_SUCCESS_OTP,
        payload: {
          userOtp: state.otp,
        },
      });
      dispatch({ type: USER_SUCCESS_MESSAGE_CLEAR });
      navigate("/verify-code");
      // })
    }

    if (error) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        //title: "Oops...",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: error,
      }).then(() => {
        dispatch({ type: USER_ERROR_MESSAGE_CLEAR });
      });
    }
  }, [successMessage, error]);

  return (
    <div>
      <div className="container-fluid">
        {/* <header w3-include-html="header.html"></header> */}

        <div className="row page-signup">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <h1 className="page-title mx-auto">
              <span className="blue">Save Planet</span>
              with
              <span className="green">Us</span>
            </h1>
            <form
              className="text-center"
              style={{ maxWidth: "384px", margin: "auto" }}
            >
              <div className="form-floating mt-4 mb-4">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={state.name}
                  onChange={(e) => handelCreate(e)}
                  id="name"
                  placeholder="Enter name"
                />
                <label htmlFor="your-name">Enter name</label>
              </div>
              <div className="form-floating mt-4 mb-4">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={state.email}
                  onChange={(e) => handelCreate(e)}
                  id="email"
                  placeholder="Enter email"
                />
                <label htmlFor="email">Enter email</label>
              </div>
              <div className="form-floating mt-4 mb-4">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={state.password}
                  onChange={(e) => handelCreate(e)}
                  id="password"
                  placeholder="Enter password"
                />
                <label htmlFor="password">Enter password</label>
              </div>
              <div className="form-floating mt-4 mb-4">
                <input
                  type="password"
                  className="form-control"
                  name="ReEnterPassword"
                  value={state.ReEnterPassword}
                  onChange={(e) => handelCreate(e)}
                  id="password2"
                  placeholder="Re-Enter password"
                />
                <label htmlFor="password2">Re-Enter password</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  name="checkbox"
                  checked={state.checkbox}
                  onChange={(e) => handelCreate(e)}
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I agree with the process of <a href="/#">term & co</a>
                </label>
              </div>

              <button
                className="btn mt-4 green-btn-2"
                type="button"
                onClick={handelSubmit}
              >
                <span>Sign Up</span>
              </button>

              {/* <div className="social-icons">
                                <p className="mt-4 mb-4">
                                    <span>Sign Up With</span>
                                </p>
                                <div className="all-icons">
                                    <a className="icon" href='/#'>
                                        <i className="fab fa-lg fa-facebook-f"></i>
                                    </a>
                                    <a className="icon" href='/#'>
                                        <i className="fab fa-lg fa-instagram"></i>
                                    </a>
                                    <a className="icon" href='/#'>
                                        <i className="fab fa-lg fa-google"></i>
                                    </a>
                                    <a className="icon" href='/#'>
                                        <i className="fab fa-lg fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div> */}
            </form>
          </div>
          <div className="col-md-3"></div>
        </div>

        {/* <footer className="footer fixed-footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
      </div>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#156EF0"
            barColor="#019317"
          />
        </div>
      ) : null}
    </div>
  );
}
