import axios from "axios";
import {
    QUIZ_GET_SUCCESS, QUIZ_GET_FAIL,
}
    from '../types/quizType'

    let {SERVER_URI,BASE_URL} = require("../../../src/config/dev")
    const url = SERVER_URI;




export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}



export const QuizGet = (datas) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"



        const config = {
            headers: {
                'Content-Type': 'application/josn',
                // 'adminId': datas.adminId,
                'url': `${url}/user/get-quiz`
            }
        }
        const token = localStorage.getItem("authToken");
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.get(`${url}/user/get-quiz`, config)
            // console.log(response.data.data)

            dispatch({
                type: QUIZ_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    quizData: response.data.data
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: QUIZ_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}



