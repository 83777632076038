import {
    QUIZ_GET_SUCCESS, QUIZ_GET_FAIL,
}
    from '../types/quizType'


const quizState = {
    quizData: [],
    quizError: '',
    quizSuccessMessage: '',
    quizSuccessMessagee: ''
}


export const quizReducer = (state = quizState, action) => {
    const { type, payload } = action

    if (type === QUIZ_GET_SUCCESS) {
        return {
            ...state,
            quizData: payload.quizData,
            quizSuccessMessage: payload.quizSuccessMessage
        }
    }

    if(type === QUIZ_GET_FAIL) {
        return {
            ...state,
            quizError: payload.error
        }
    }


    return state;
}