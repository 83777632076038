import {
    SUBMISSION_GET_SUCCESS,
    SUBMISSION_GET_FAIL,
    SUBMISSION_CREATE_SUCCESS,
    SUBMISSION_CREATE_FAIL
} from '../types/submissionType';

const submissionState = {
    submissionData: [],
    submissionError: '',
    submissionSuccessMessage: '',
}

export const SubmissionReducer = (state = submissionState, action) => {
    const { type, payload } = action

    if(type === SUBMISSION_CREATE_SUCCESS){
        return {
            ...state,
            submissionSuccessMessage: payload.successMessage,
            submissionError: ""
        }
    }
    if(type === SUBMISSION_CREATE_FAIL){ 
        return {
            ...state,
            submissionSuccessMessage: "",
            submissionError: payload.error
        }
    }
    if(type === SUBMISSION_GET_SUCCESS){
        // console.log(33,payload.data)
        return {
            ...state,
            submissionSuccessMessage: payload.successMessage,
            submissionData: payload.data,
            submissionError: ""
        }
    }
    if(type === SUBMISSION_GET_FAIL){
        return {
            ...state,
            submissionSuccessMessage: "",
            submissionError: payload.error
        }
    }

    return state;
}