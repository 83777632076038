import {
    LEADERBOARD_GET_SUCCESS,
    LEADERBOARD_GET_FAIL,
    LEADERBOARD_GET_ONE_SUCCESS,
    LEADERBOARD_GET_ONE_FAIL,
    LEADERBOARD_CREATE_SUCCESS,
    LEADERBOARD_CREATE_FAIL,
} from '../types/leaderBoardTypes';

const leaderboardState = {
    leaderboardData: [],
    myLeaderBoardData: [],
    leaderboardError: '',
    leaderboardSuccessMessage: '',
}

export const LeaderBoardReducer = (state = leaderboardState, action) => {
    const { type, payload } = action;

    if(type === LEADERBOARD_CREATE_SUCCESS){
        return {
            ...state,
            leaderboardSuccessMessage: payload.successMessage,
            leaderboardError: ""
        }
    }

    if(type === LEADERBOARD_CREATE_FAIL){ 
        return {
            ...state,
            leaderboardSuccessMessage: "",
            leaderboardError: payload.error
        }
    }

    if(type === LEADERBOARD_GET_SUCCESS){
        return {
            ...state,
            leaderboardSuccessMessage: payload.successMessage,
            leaderboardData: payload.data,
            leaderboardError: ""
        }
    }

    if(type === LEADERBOARD_GET_FAIL){
        return {
            ...state,
            leaderboardSuccessMessage: "",
            leaderboardError: payload.error
        }
    }

    if(type === LEADERBOARD_GET_ONE_SUCCESS){
        return {
            ...state,
            leaderboardSuccessMessage: payload.successMessage,
            myLeaderBoardData: payload.data,
            leaderboardError: ""
        }
    }

    if(type === LEADERBOARD_GET_ONE_FAIL){
        return {
            ...state,
            leaderboardSuccessMessage: "",
            leaderboardError: payload.error
        }
    }


    return state;
}
