import {
    QUESTION_GET_SUCCESS, QUESTION_GET_FAIL,
    QUESTION_GET_SUCCESS_CLEAR
}
    from '../types/questionType'


const questionState = {
    questionData: [],
    questionError: '',
    questionSuccessMessage: '',
    questionSuccessMessagee: ''
}


export const questionReducer = (state = questionState, action) => {
    const { type, payload } = action

    if (type === QUESTION_GET_SUCCESS) {
        return {
            ...state,
            questionData: payload.questionData,
            questionSuccessMessage: payload.questionSuccessMessage
        }
    }

    if(type === QUESTION_GET_SUCCESS_CLEAR){
        return {
            ...state,
            questionData: []
        }
    }

    if(type === QUESTION_GET_FAIL) {
        return {
            ...state,
            questionError: payload.error
        }
    }

    return state;
}