import { React, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./FrontEnd/Pages/SignIn/SignIn";
import SignUp from "./FrontEnd/Pages/SignUp/SignUp";
import Header from "./FrontEnd/Component/Header/Header";
import Footer from "./FrontEnd/Component/Footer/Footer";
import ForgotPassword from "./FrontEnd/Pages/Forgot-Password/ForgotPassword";
import VerifyCode from "./FrontEnd/Pages/Verify-Code/verifycode";
import ResetPassword from "./FrontEnd/Pages/Reset-Password/ResetPassword";
import Quiz from "./FrontEnd/Pages/Quiz/Quiz";
import QuizWaiting from "./FrontEnd/Pages/Quiz-Waiting/QuizWaiting";
import QuizComplete from "./FrontEnd/Pages/Quiz-Complete/QuizComplete";
import News from "./FrontEnd/Pages/News/News";
import NewsDetail from "./FrontEnd/Pages/News-Detail/NewsDetail";
import MyAccount from "./FrontEnd/Pages/My-Account/MyAccount";
import Leaderboard from "./FrontEnd/Pages/Leaderboard/Leaderboard";
import Home from "./FrontEnd/Pages/Home/Home";
import ExtraQuiz from "./FrontEnd/Pages/Extra-Quiz/ExtraQuiz";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import deCodeToken from 'jwt-decode'

function App() {
  // let logintoken = localStorage.getItem('authToken');
  // const tokenDecodedData = deCodeToken(logintoken)
  // console.log(30,tokenDecodedData);

  const { successMessage, error, authenticate, userOtp, userRData, myInfo } =
    useSelector((state) => state.auth);

  let newspath = localStorage.getItem("newsPath");
  // console.log(38, newspath);

  let routes = [
    {
      path: "/quiz",
      element: Quiz,
    },
    {
      path: "/extra-quiz",
      element: ExtraQuiz,
    },
    {
      path: "/quiz-waiting/:dailyQuizId",
      // path: "/quiz-waiting",
      element: QuizWaiting,
    },
    {
      path: "/quiz-complete/:dailyQuizId",
      element: QuizComplete,
    },
    {
      path: "/news",
      element: News,
    },
    {
      path: `/news-details/${newspath}`,
      element: NewsDetail,
    },
    {
      path: "/my-account",
      element: MyAccount,
    },
    {
      path: "/leaderboard",
      element: Leaderboard,
    },
  ];

  const { token } = useParams();


  console.log(userOtp);
  

  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route path="/login" exact element={<SignIn />}></Route>
        <Route path="/signup" exact element={<SignUp />}></Route>
        <Route path="/news" exact element={<News />}></Route>
        <Route path="/news-details/:Id" exact element={<NewsDetail />}></Route>
        <Route path="/" exact element={<Home />}></Route>
        <Route
          path="/forgot-password"
          exact
          element={<ForgotPassword />}
        ></Route>
        {userOtp && userOtp.length > 0 ? (
          <Route path="/verify-code" exact element={<VerifyCode />}></Route>
        ) : (
          <Route
            path="/verify-code"
            exact
            element={<Navigate replace to="/login" />}
          ></Route>
        )}
        <Route
          path="/reset-password/:token"
          exact
          element={<ResetPassword />}
        ></Route>

        {authenticate ? (
          routes.map((Item, index) => {
            return (
              <Route path={Item.path} exact element={<Item.element />}></Route>
            );
          })
        ) : (
          <Route
            path="*"
            exact
            element={<Navigate replace to="/login" />}
          ></Route>
        )}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
