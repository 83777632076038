import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { useDispatch } from 'react-redux';
import { useState } from "react";
import { QuizGet } from "../../../stores/actions/quizAction";
import { QuestionGet } from "../../../stores/actions/questionAction";
import {
  submissionCreate,
  submissionGet,
} from "../../../stores/actions/submissionAction";

import { ProgressBar } from "react-loader-spinner";
import Swal from "sweetalert2";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { Helmet } from "react-helmet";

import { QUESTION_GET_SUCCESS_CLEAR } from "../../../stores/types/questionType"

const { BASE_URL } = require("../../../config/dev");

export default function ExtraQuiz() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { quizData, quizError, quizSuccessMessage, quizSuccessMessagee } =
    useSelector((state) => state.quiz);

  const {
    questionData,
    questionError,
    questionSuccessMessage,
    questionSuccessMessagee,
  } = useSelector((state) => state.question);

  const {
    submissionData,
    submissionError,
    submissionSuccessMessage,
    submissionSuccessMessagee,
  } = useSelector((state) => state.submission);

  const { myInfo } = useSelector((state) => state.auth);

  const [newQuiz, setNewQuiz] = useState(null);

  const [userAnswers, setUserAnswers] = useState(null);
  const [userResponses, setUserResponses] = useState([]);
  const [call, setCall] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedButtonId, setSelectedButtonId] = useState(null);

  const [condLoader, setConLoader] = useState(false);

  const [loaders, setLoaders] = React.useState(false);


  useEffect(() => {
    if (myInfo && myInfo.Id && submissionData && submissionData.length == 0) {
      dispatch(submissionGet(myInfo.Id));
    }
  }, []);

  // console.log(69,submissionData);


  useEffect(() => {
    if (quizData && quizData.length > 0) {

      const ArrayOfTodayQuiz = [];
      //today date
      let date = new Date().toISOString().split("T")[0];

      quizData.forEach((sq) => {
        let x = sq?.liveDate || "";
        x = x.split("T")[0];

        if (x === date) {
          ArrayOfTodayQuiz.push(sq._id);
        }
      });

      //console.log("Today's All Quiz", ArrayOfTodayQuiz, submissionData);

      if (ArrayOfTodayQuiz.length === 0) {
        navigate(`/quiz-waiting/000`);
      } else if (submissionData && submissionData.length > 0) {

        const AllAttemptedUniqueQuizIds = new Set(
          submissionData.map((item) => item.quizId)
        );
        //console.log("All Submitted Quiz Ids:", AllAttemptedUniqueQuizIds);

        const AttemptedDailyQuizIds = ArrayOfTodayQuiz.filter((element) =>
          AllAttemptedUniqueQuizIds.has(element)
        );
        //console.log("You played Today's Quiz:", AttemptedDailyQuizIds);

        //if not attempted any todaysQuiz then send user to daily quiz
        if (AttemptedDailyQuizIds.length === 0) {
          /* send user to daily quiz page */
          //console.log("send user to daily quiz page");
          // setConLoader(true);
        }
        else {

          //if he played daly quiz then (else)
          const unplayedQuizIds = ArrayOfTodayQuiz.filter(
            (element) => !AllAttemptedUniqueQuizIds.has(element)
          );
          //console.log("Unplayed Quiz:", unplayedQuizIds);

          /* no quiz left as unplayed */
          if (unplayedQuizIds.length === 0) {

            /* quiz waiting -- no more quiz */

            // console.log("if conditions 1")
            let DailyQuizId = localStorage.getItem("DailyQuizId") || "000";
            navigate(`/quiz-waiting/${DailyQuizId}`);
          }

          if (AttemptedDailyQuizIds.length > 0 && unplayedQuizIds.length > 0) {
            // console.log("if conditions 2")
            const randomIndex = Math.floor(
              Math.random() * unplayedQuizIds.length
            );
            const randomQuizId = unplayedQuizIds[randomIndex];
            // console.log("Random Quiz ID:", randomQuizId);

            // console.log("You can play an extra quiz:", randomQuizId);
            localStorage.setItem("ExtraQuizIds", randomQuizId);
            setNewQuiz(randomQuizId);
          }

        }

      } else if (submissionData && submissionData.length == 0) {
        setConLoader(true);
      }
    }
  }, [quizData]);


  const [curQuestions, setcurQuestions] = React.useState(questionData[0]);
  const [curIndex, setcurIndex] = React.useState(0);

  useEffect(() => {
    if (newQuiz && newQuiz.length > 23) {
      dispatch(QuestionGet(newQuiz));
    }
  }, [newQuiz]);

  useEffect(() => {
    if (myInfo) {
      dispatch(submissionGet(myInfo.Id));
    }
  }, [myInfo]);

  /** Setting First Question */
  useEffect(() => {
    if (!curQuestions) {
      setcurQuestions(questionData[0]);
      setcurIndex(0);
    }
  }, [questionData && questionData.length > 0]);

  const handleOptionClick = (selectedOption, selectedButtonId) => {
    // Remove "select" class from previously selected button
    if (selectedButtonId) {
      let previousSelectedButton = document.getElementById(selectedButtonId);
      previousSelectedButton.classList.remove("select");
    }

    // Add "select" class to the clicked button
    let selectedButton = document.getElementById(selectedOption);
    selectedButton.classList.add("select");

    // Update selected option and button ID
    setSelectedOption(selectedOption);
    // setSelectedButtonId(selectedOption);
    setUserAnswers(selectedOption);
  };

  /* Button specific submission and Next */
  const NextQustion = (index) => {
    // console.log(43, index, questionData.length, userAnswers)

    if (selectedButtonId) {
      let previousSelectedButton = document.getElementById(selectedButtonId);
      previousSelectedButton.classList.remove("select");
    }

    setSelectedOption(null);
    setSelectedButtonId(null);

    if (userAnswers !== null && index <= questionData.length) {
      setLoaders(true)

      if (curQuestions.correctAnswer === userAnswers) {
        // console.log("Correct Answer");

        // Handle the correct answer: update className of correct option button
        let correctButton = document.getElementById(userAnswers);

        if (correctButton) {
          correctButton.classList.add("correct");
        }

        /* setting data */
        const data = {
          participant: myInfo?.Id || null,
          questionId: curQuestions?._id || null,
          quizId: newQuiz,
          questionTitle: curQuestions?.qusTitle || null,
          userAnswer: userAnswers,
          options: curQuestions?.options || null,
          correctAnswer: curQuestions?.correctAnswer || null,
          responsesTime: ``,
        };

        setUserResponses([...userResponses, data]);


        setTimeout(() => {
          /* Jumping to next question */
          if (index !== questionData.length) {
            setUserAnswers(null);
            correctButton.classList.remove("correct");
            setcurQuestions(questionData[index]);
            setcurIndex(index);
          }
          setLoaders(false)
        }, 1000);
      } else if (curQuestions.correctAnswer !== userAnswers) {
        // console.log("Incorrect Answer");

        // // Handle the incorrect answer: update className of selected option button
        let selectedButton = document.getElementById(userAnswers);
        if (selectedButton) {
          selectedButton.classList.add("incorrect");
        }

        // // Handle the correct answer: update className of correct option button
        let correctButton = document.getElementById(curQuestions.correctAnswer);
        if (correctButton) {
          correctButton.classList.add("correct");
        }

        /* setting data */
        const data = {
          participant: myInfo?.Id || null,
          questionId: curQuestions?._id || null,
          quizId: newQuiz,
          questionTitle: curQuestions?.qusTitle || null,
          userAnswer: userAnswers,
          options: curQuestions?.options || null,
          correctAnswer: curQuestions?.correctAnswer || null,
          responsesTime: ``,
        };

        setUserResponses([...userResponses, data]);

        // console.log(index, userResponses);

        setTimeout(() => {
          /* Jumping to next question */
          if (index !== questionData.length) {
            setUserAnswers("");
            selectedButton.classList.remove("incorrect");

            correctButton.classList.remove("correct");

            setcurQuestions(questionData[index]);
            setcurIndex(index);
          }

          if (index === questionData.length) {
            // console.log("Question no: 8::::::::DB CALL", userResponses);
            setCall(true);
          }
          setLoaders(false)
        }, 1000);
      }

      if (index === questionData.length) {
        // console.log("Question no: 8::::::::DB CALL")
        setCall(true);
      }
    }
  };


  useEffect(() => {
    // console.log("in db call" + call)
    if (call) {
      // console.log(":::DB CALL:::", userResponses)
      dispatch(submissionCreate(userResponses)).then(() => {
        setCall(false);
        dispatch({
          type: QUESTION_GET_SUCCESS_CLEAR
        })
        setUserResponses([]);
        let DailyQuizId = localStorage.getItem("DailyQuizId");
        navigate(`/quiz-complete/${DailyQuizId}`);
      });
    }
  }, [call]);

  /****************************ADVERTISE SECTION***********************************/

  useEffect(() => {
    const index = Number(curIndex) + 1;
    if (index === 3 || index === 5 || index === 7) {
      console.log("show advertisement");
      openModal();
    }
  }, [curIndex])


  const [advCloseBtn, setAdvCloseBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    //console.log("open modal")
    onOpenModal(true);
    setAdvCloseBtn(false);
  };


  useEffect(() => {
    const index = Number(curIndex) + 1;
    if (index === 3 || index === 5 || index === 7) {
      //console.log("show advertisement");
      openModal();
    }
  }, [curIndex])

  useEffect(() => {
    //console.log(219, open);
    if (open) {
      setTimeout(() => {
        setAdvCloseBtn(true);
      }, 10000);
    }
  }, [open])





  return (
    <>
      <Helmet>
        <title>Planet Quizzer -Extra Quiz</title>
        <meta
          name="description"
          content="Welcome to our quiz website's news page! Dive into a world of knowledge, entertainment, and discovery. Stay up-to-date with the latest quiz trends and news. Explore captivating quizzes, thrilling trivia, and thought-provoking articles, all in one place. Challenge yourself, discover new facts, and immerse yourself in the exciting world of quizzes. Join us on this journey of fun and learning today!"
        />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta
          property="og:description"
          content="Welcome to our quiz website's news page! Dive into a world of knowledge, entertainment, and discovery. Stay up-to-date with the latest quiz trends and news. Explore captivating quizzes, thrilling trivia, and thought-provoking articles, all in one place. Challenge yourself, discover new facts, and immerse yourself in the exciting world of quizzes. Join us on this journey of fun and learning today!"
        />
        <meta
          property="og:image"
          content={BASE_URL + "/" + "images/profilePic.png"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link
          rel="image_src"
          href={BASE_URL + "/" + "images/profilePic.png"}
        ></link>
      </Helmet>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignCtems: "center",
          height: "100vh",
        }}
      >

        <div width="75%" height="50%" >
          <Modal open={open} onClose={onCloseModal} showCloseIcon={advCloseBtn} closeOnEsc={false} closeOnOverlayClick={false} center>
            <div className="App" style={{margin:"3%"}}>
              <video width="100%" height="80%" controls >
                <source src="https://www.taxmann.com/emailer/images/FEMA.mp4" type="video/mp4" />
              </video>
            </div>
          </Modal>
        </div>



        {condLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // position: "absolute",
              // top: "0",
              // bottom: "0",
              // left: "0",
              // right: "0",
              // backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <div className="row justify-content-md-center mb-3 text-center home-center">
              <div className="col-lg-7">
                <h1 className="home-heading">
                  Play
                  <span className="blue">your</span>
                  daily
                  <span className="blue"> quiz</span>
                  <span className="green">first</span>
                </h1>
                {/* <button
              className="btn green-btn-1"
              onClick={() => navigate("/quiz")}
            >
              <span>Daily Quiz</span>
            </button> */}
              </div>
            </div>
          </div>
        ) : (
          <>
            {questionData && questionData.length > 0 ? (
              <>
                <div className="container-fluid">
                  <div className="row " >
                    <div className="col-lg-12" style={{ backgroundColor: "grey", height: "90px" }}>
                    </div>
                    <div className="col-lg-3 border" style={{ backgroundColor: "grey" }}>
                      {/* <div className="left-bar-one" >Left</div> */}
                    </div>

                    <div className="col-lg-6 border">
                      <div className="row d-flex justify-content-center align-items-center page-signup">
                        {/* <div className="col-md-2"></div> */}

                        <div className="col-md-12">
                          <form id="regForm" action="/action_page.php">
                            {/* <!-- Circles which indicates the steps of the form: --> */}
                            <div className="all-steps">
                              {questionData && questionData.length > 0
                                ? questionData.map((_, index) => (
                                  <span
                                    className={`step ${index === 0 ? "first-child" : ""
                                      } ${index === questionData.length - 1
                                        ? "last-child"
                                        : ""
                                      }`}
                                    key={index}
                                  >
                                    {index === curIndex &&
                                      curIndex !== 0 &&
                                      curIndex !== questionData.length - 1 ? (
                                      <span
                                        style={{
                                          backgroundColor: "#019317",
                                          borderRadius: "50%",
                                          height: "15px",
                                          width: "15px",
                                          display: "block",
                                          position: "absolute",
                                        }}
                                      ></span>
                                    ) : index === 0 ||
                                      index === questionData.length - 1 ? (
                                      <span>{`0${index + 1}`}</span>
                                    ) : null}
                                  </span>
                                ))
                                : null}
                            </div>

                            {/* <div className="all-steps">
                                  <span className="step">
                                      {curIndex >= 0 && curIndex < 8 ? <span>{`${curIndex + 1}`}</span> : null}
                                  </span>
                              </div> */}

                            <div className="tab quiz">
                              <h1 className="question">
                                <span>{curQuestions?.qusTitle || ""}</span>
                              </h1>

                              {/* correct incorrect options */}
                              <div className="options">
                                {curQuestions &&
                                  curQuestions.options &&
                                  curQuestions.options.length > 0
                                  ? // curQuestions?.options.map((value, index) => {
                                  //     return (
                                  //         <button type="button"
                                  //             className=""
                                  //             key={index}
                                  //             id={value}
                                  //             style={{
                                  //                 display: "flex",
                                  //                 flexDirection: "row",
                                  //                 justifyContent: "center",
                                  //                 gap: "5%"
                                  //             }}
                                  //             onClick={() => handleOptionClick(value)}
                                  //         >
                                  //             <i className="fa-solid fa-circle" style={{ marginTop: "4%" }}></i>
                                  //             {value}
                                  //         </button>
                                  //     )
                                  // })

                                  curQuestions?.options.map((value, index) => {
                                    return (
                                      <button
                                        type="button"
                                        className=""
                                        key={index}
                                        id={value}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          gap: "5%",
                                          backgroundColor:
                                            selectedOption === value
                                              ? "grey"
                                              : "#D3E7FF",
                                          color:
                                            selectedOption === value
                                              ? "white"
                                              : "initial",
                                        }}
                                        onClick={() =>
                                          handleOptionClick(
                                            value,
                                            selectedButtonId
                                          )
                                        }
                                      >
                                        <i
                                          className="fa-solid fa-circle"
                                          style={{ marginTop: "4%" }}
                                        ></i>
                                        {value}
                                      </button>
                                    );
                                  })
                                  : null}
                              </div>

                              <div className="submit-answer">
                                <button
                                  type="button"
                                  onClick={() => NextQustion(Number(curIndex) + 1)}
                                  className=""
                                >
                                  {loaders ? (
                                    <i class="fa fa-spinner fa-spin"></i>
                                  ) : null}
                                  &nbsp;&nbsp; Submit Answer
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="col-md-2"></div>
                      </div>
                    </div>

                    <div className="col-lg-3 border" style={{ backgroundColor: "grey" }}>
                      {/* <div className="left-bar-one " >Right</div> */}
                    </div>
                    <div className="col-lg-12 border" style={{ backgroundColor: "grey", height: "90px" }}>
                      {/* <div className="buttom-bar-one " >Button</div> */}
                    </div>

                  </div>



                  {/* <footer className="footer fixed-footer">
                      <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                  </footer> */}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#156EF0"
                  barColor="#019317"
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
