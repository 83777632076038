import {
    LEADERBOARD_GET_SUCCESS,
    LEADERBOARD_GET_FAIL,
    LEADERBOARD_GET_ONE_SUCCESS,
    LEADERBOARD_GET_ONE_FAIL,
    LEADERBOARD_CREATE_SUCCESS,
    LEADERBOARD_CREATE_FAIL,
} from '../types/leaderBoardTypes';


import axios from 'axios';


let {SERVER_URI,BASE_URL} = require("../../../src/config/dev")
const url = SERVER_URI;



export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const leaderboardCreate = (datas) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        const token = localStorage.getItem("authToken");

        try {
            userAuth(token)
            const response = await axios.post(`${url}/user/create-leaderboard`, datas)
            // console.log(response.data.data)

            dispatch({
                type: LEADERBOARD_CREATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                }
            })
        }

        catch (error) {
            let data = error.response.data.message
            dispatch({
                type: LEADERBOARD_CREATE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const leaderboardGetAll = (datas) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        const config = {
            headers: {
                'Content-Type': 'application/josn',
                // 'url': `${url}/user/get-comment`,
                // 'newsId' : datas.newsId
            }
        }
        // const token = localStorage.getItem("authToken");
        try {
            // console.log(datas)
            // userAuth(token)
            const response = await axios.get(`${url}/user/get-leaderboard`, config)
            // console.log(71,response.data.data)

            dispatch({
                type: LEADERBOARD_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    data: response.data.data
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: LEADERBOARD_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const leaderboardGet = (datas) => {
    return async (dispatch) => {

        // const url = 'http://localhost:8080'
        // const url = "https://planateq-server.azurewebsites.net"


        const config = {
            headers: {
                'Content-Type': 'application/josn',
                // 'url': `${url}/user/get-comment`,
                // 'newsId' : datas.newsId,
                userid: datas,
            }
        }
        // const token = localStorage.getItem("authToken");
        try {
            // console.log(datas)
            // userAuth(token)
            const response = await axios.get(`${url}/user/get-leaderboard-id`, config)
            // console.log(7100, response.data.data)

            dispatch({
                type: LEADERBOARD_GET_ONE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    data: response.data.data
                }
            })

        } catch (error) {
            let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: LEADERBOARD_GET_ONE_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}