import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../../stores/actions/authAction";
import { useSelector } from "react-redux";
import { userRPassword } from "../../../stores/actions/authAction";
import {
  USER_SUCCESS_MESSAGE_CLEAR,
  USER_ERROR_MESSAGE_CLEAR,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
} from "../../../stores/types/authType";

import {
  getLoginUserData,
  userUpdate,
} from "../../../stores/actions/authAction";

import avatar from "../../../img/avatar.png";
import tree from "../../../img/trees.svg";

import { ProgressBar } from "react-loader-spinner";

export default function MyAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [score, setScore] = useState(0);
  const [position, setPosition] = useState(0);

  const findMyPosition = (myData, leaderBoardAllData) => {
    // console.log(20, myData, leaderBoardAllData);
    // Create a new array with the updated data
    const updatedLeaderboard = [...leaderBoardAllData, myData];

    // Sort the updated leaderboard data in descending order based on score
    const sortedLeaderboard = updatedLeaderboard.sort(
      (a, b) => b.score - a.score
    );

    // Update positions in the sorted leaderboard
    const updatedLeaderboardWithPositions = sortedLeaderboard.map(
      (entry, index) => ({
        ...entry,
        position: index + 1,
      })
    );

    return updatedLeaderboardWithPositions;
  };

  const {
    successMessage,
    error,
    authenticate,
    userOtp,
    curUserData,
    userRData,
    myInfo,
  } = useSelector((state) => state.auth);

  const {
    leaderboardData,
    myLeaderBoardData,
    leaderboardError,
    leaderboardSuccessMessage,
  } = useSelector((state) => state.leaderBoard);

  useEffect(() => {
    if (leaderboardData) {
      const updatedLeaderboard = [...leaderboardData];
      const sortedLeaderboard = updatedLeaderboard.sort(
        (a, b) => b.score - a.score
      );
      sortedLeaderboard.map((se, index) => {
        if (se.userId === myInfo.Id) {
          let s = se.score;
          let p = index + 1;
          setPosition(p);
          setScore(s);
        }
      }, []);
    }
  }, [leaderboardData && leaderboardData.length > 0]);

  useEffect(() => {
    if (myInfo && myInfo.Id && myInfo.Id.length > 0) {
      dispatch(getLoginUserData({ userId: myInfo.Id }));
    }
  }, [myInfo]);

  const [loaders, setLoaders] = React.useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [state, setState] = useState(null);
  const [preview, setPreview] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChange = (name, value) => {
    if (name === "name") {
      setState({
        ...state,
        ["fname"]: value.split(" ")[0],
        ["lname"]: value.split(" ")[1] != undefined ? value.split(" ")[1] : "",
      });
    } else if (name !== "name") {
      setState({ ...state, [name]: value });
    }
  };

  /*submit or edit */
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("userId", state.userId);
    formData.append("fname", state.fname);
    formData.append("lname", state.lname);
    formData.append("bio", state.bio);
    formData.append("email", state.email);
    formData.append("file", state.photo);

    if (
      state.password &&
      state.password.length > 0 &&
      state.password !== state.confirmPassword
    ) {
      Swal.fire({
        icon: "error",
        text: "Password and Confirm Password not matched",
      });

      // return;
    } else if (state.fname === "") {
      Swal.fire({
        icon: "error",
        text: "Please enter user full name!",
      });
    } else {
      setLoaders(true)
      dispatch(userUpdate(formData)).then(()=>{
        setLoaders(false)
      })
    }
  };

  /* sweet alert */
  useEffect(() => {
    if (successMessage) {
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: successMessage,
        showConfirmButton: false,
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        timer: 2500,
      }).then(() => {
        dispatch({ type: USER_SUCCESS_MESSAGE_CLEAR });
      });
    }

    if (error) {
      setLoader(false);

      Swal.fire({
        icon: "error",
        //title: "Oops...",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: error,
      }).then(() => {
        dispatch({ type: USER_ERROR_MESSAGE_CLEAR });
      });
    }
  }, [successMessage, error]);

  /* setting up exisisting data for preview */
  useEffect(() => {
    if (curUserData) {
      let obj = {
        userId: curUserData._id,
        fname: curUserData && curUserData.fname ? curUserData.fname : "",
        lname: curUserData && curUserData.lname ? curUserData.lname : "",
        bio: curUserData && curUserData.bio ? curUserData.bio : "",
        email: curUserData && curUserData.email ? curUserData.email : "",
        photo: curUserData && curUserData.photo ? curUserData.photo : "",
      };
      setState(obj);
      // setPreviewUrl(state.photo || curUserData.photo )
      setPreview(true);
    }
  }, [curUserData]);

  /* useref for photo file  */
  const fileInputRef = useRef();

  /* img change button */
  const handleButtonClick = () => {
    // Trigger the click event on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /* photo change */
  const handleFileChange = (name, value) => {
    setPreviewUrl(URL.createObjectURL(value));
    setState({ ...state, photo: value });
  };

  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#156EF0"
            barColor="#019317"
          />
        </div>
      ) : (
        <>
          {preview ? (
            <div>
              <div className="container-fluid">
                {/* <header w3-include-html="header.html"></header> */}

                <div className="row justify-content-center align-items-center my-account">
                  {/* <!-- <div className="row"> --> */}
                  <h1 className="page-title">
                    <span className="blue">My</span>
                    <span className="green">Account</span>
                  </h1>
                  {/* <!-- </div> --> */}

                  <div className="row mt-4">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 first-col">
                      <div className="srt-contain">
                        <div className="score">
                          {position}
                          <div className="avatar">
                            <img
                              alt=""
                              src={
                                previewUrl
                                  ? previewUrl
                                  : curUserData && curUserData.photo
                                  ? curUserData.photo
                                  : "./images/avatar.png"
                              }
                            />
                            <img
                              alt=""
                              className="camera"
                              src="./images/camera.svg"
                              onClick={handleButtonClick}
                            ></img>
                            <input
                              accept="image/*"
                              name="image"
                              onChange={(e) =>
                                handleFileChange(
                                  e.target.name,
                                  e.target.files[0]
                                )
                              }
                              ref={fileInputRef}
                              id="upload-avatar-pic"
                              type="file"
                              hidden
                            />
                            {/* <input
                              type="file"
                              id="file"
                              ccept="image/*"
                              ref={fileInputRef}
                              onChange={handleFileChange}
                              style={{}}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-4 col-sm-12 second-col">
                      {/* <p>My name
                                  <a>
                                      <i className="fas fa-lg fa-pen"></i>
                                      Edit
                                  </a>
                              </p> */}
                      <div className="flex-container mt-2">
                        {/* <div className="name">
                                      {state && state.fname && state.lname ? <textarea type="text" onBlur={(e) => handleSaveName(e)} className="form-control" id="name" placeholder="Enter name" >
                                          {state && state.fname && state.lname ? `${state.fname} ${state.lname}` : ''}
                                      </textarea> : null}
                                  </div> */}
                        <div className="trees">
                          {score}
                          <img alt="" src={tree} />
                        </div>
                      </div>
                      <div className="mt-3">{/* My bio */}</div>
                      {/* <div className="mt-2"> */}
                      {/* {state && state.bio && state.bio.length > 0 ? ( */}
                      <textarea
                        name="bio"
                        rows="4"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        className="bio"
                        placeholder="Enter your bio..."
                        style={{ padding: "2%" }}
                      >
                        {state && state.bio ? state.bio : ""}
                      </textarea>
                      {/* ) : null} */}
                      {/* </div> */}
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 third-col">
                      <form>
                        <p className="separator-2">
                          Leave password fields empty if you not want to change.
                        </p>

                        <div className="form-floating mt-4 mb-4">
                          {curUserData && curUserData.fname ? (
                            <textarea
                              type="text"
                              name="name"
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              className="form-control"
                              id="name"
                              placeholder="Enter name"
                            >
                              {curUserData
                                ? `${curUserData.fname} ${
                                    curUserData?.lname || ""
                                  }`
                                : ""}
                            </textarea>
                          ) : null}
                          <label htmlFor="your-name">Enter name</label>
                        </div>

                        <div className="form-floating mt-4 mb-4">
                          {state && state.email ? (
                            <textarea
                              type="text"
                              readOnly
                              className="form-control"
                              id="name"
                              placeholder="Enter name"
                            >
                              {state && state.email ? state.email : ""}
                            </textarea>
                          ) : null}
                          <label htmlFor="email">Enter email</label>
                        </div>

                        <div className="form-floating mt-4 mb-4">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            value={state.password}
                            placeholder="Enter password"
                          />
                          <label htmlFor="password">Enter password</label>
                        </div>

                        <div className="form-floating mt-4 mb-4">
                          <input
                            type="password"
                            className="form-control"
                            id="password2"
                            name="confirmPassword"
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            value={state.confirmPassword}
                            placeholder="Re-Enter password"
                          />
                          <label htmlFor="password">Confirm password</label>
                        </div>

                        <button
                          className="btn mt-4 mb-4 green-btn-2"
                          onClick={handleSubmit}
                        >
                          {loaders ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : null}
                          &nbsp;&nbsp;
                          <span>Save Changes</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* <footer className="footer fixed-footer">
                      <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                  </footer> */}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              }}
            >
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#156EF0"
                barColor="#019317"
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
