import {
    COMMENT_GET_SUCCESS, COMMENT_GET_FAIL, COMMENT_CREATE_SUCCESS , COMMENT_CREATE_FAIL ,
     COMMENT_DELETE_SUCCESS ,COMMENT_DELETE_FAIL
}from '../types/commentType'


const commentState = {
    commentData: [],
    commentError: '',
    commentSuccessMessage: '',
    commentSuccessMessagee: ''
}


export const commentReducer = (state = commentState, action) => {
    const { type, payload } = action

    if(type === COMMENT_CREATE_SUCCESS){
        return {
            ...state,
            commentData: payload.commentData,
            commentSuccessMessagee: payload.commentSuccessMessagee,
            commentError: ""
        }
    }

    if(type === COMMENT_CREATE_FAIL){
        return {
            ...state,
            commentError: payload.error
        }
    }

    if (type === COMMENT_GET_SUCCESS) {
        return {
            ...state,
            commentData: payload.commentData,
            commentSuccessMessage: payload.commentSuccessMessage
        }
    }

    if(type === COMMENT_GET_FAIL) {
        return {
            ...state,
            commentError: payload.error
        }
    }

    if(type === COMMENT_DELETE_SUCCESS) {
     return{
        ...state,
        commentSuccessMessagee: payload.message,
     }
    }

    if(type === COMMENT_DELETE_FAIL) {
        return{
            ...state,
            commentError: payload.error
        }
    }


    return state;
}