import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  submissionCreate,
  submissionGet,
} from "../../../stores/actions/submissionAction";
import {
  leaderboardCreate,
  leaderboardGetAll,
  leaderboardGet,
} from "../../../stores/actions/leaderBoardAction";

import avatar from "../../../img/avatar.png";
import tree from "../../../img/trees.svg";
import { Link } from "react-router-dom";

import { ProgressBar } from "react-loader-spinner";

import { Helmet } from "react-helmet";
const { BASE_URL } = require("../../../config/dev");

export default function QuizWaiting() {
  const findMyPosition = (myData, leaderBoardAllData) => {
    // console.log(20, myData, leaderBoardAllData);
    // Create a new array with the updated data

    const updatedLeaderboard = [...leaderBoardAllData, myData];

    // Sort the updated leaderboard data in descending order based on score
    const sortedLeaderboard = updatedLeaderboard.sort(
      (a, b) => b.score - a.score
    );

    // Update positions in the sorted leaderboard
    const updatedLeaderboardWithPositions = sortedLeaderboard.map(
      (entry, index) => ({
        ...entry,
        position: index + 1,
      })
    );

    return updatedLeaderboardWithPositions;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [score, setScore] = useState(0);
  const [position, setPosition] = useState(0);
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    leaderboardData,
    myLeaderBoardData,
    leaderboardError,
    leaderboardSuccessMessage,
  } = useSelector((state) => state.leaderBoard);
  const { myInfo, curUserData } = useSelector((state) => state.auth);

  useEffect(() => {
    if (leaderboardData && leaderboardData.length > 0) {
      // console.log(leaderboardData);
      const updatedLeaderboard = [...leaderboardData];
      // console.log(updatedLeaderboard);
      const sortedLeaderboard = updatedLeaderboard.sort(
        (a, b) => b.score - a.score
      );

      sortedLeaderboard.map((se, index) => {
        if (se.userId === myInfo.Id) {
          let s = se.score;
          let p = index + 1;
          setPosition(p);
          setScore(s);
          setPhoto(se.photo);
        }
      }, []);

            if (!loading) {
              setTimeout(() => {
                setLoading(true);
              }, 2000);
            }
    }
  }, [leaderboardData]);



  return (
    <>
      <Helmet>
        <title>Planet Quizzer -Quiz-waiting</title>
        <meta
          name="description"
          content="Explore our diverse collection of quizzes and test your knowledge. Stay tuned for new challenges and engaging content. Join our quiz-loving community today!"
        />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta
          property="og:description"
          content="Explore our diverse collection of quizzes and test your knowledge. Stay tuned for new challenges and engaging content. Join our quiz-loving community today!"
        />
        <meta
          property="og:image"
          content={BASE_URL + "/" + "images/profilePic.png"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link
          rel="image_src"
          href={BASE_URL + "/" + "images/profilePic.png"}
        ></link>
      </Helmet>

      {loading ? (
        <div className="container-fluid">
          {/* <header w3-include-html="header.html"></header> */}

          <div className="row d-flex justify-content-center align-items-center page-signup">
            <div className="col-md-6">
              <form id="regForm">
                {/* <!-- Circles which indicates the steps of the form: --> */}
                {/* <!-- <div className="all-steps">
                <span className="step"><span>01</span></span>
                <span className="step"><span>08</span></span>
            </div> --> */}

                <div className="tab quiz">
                  <h1 className="page-title mt-4">
                    <span className="blue">My</span>
                    <span className="green">Score</span>
                  </h1>

                  <div className="my-scores mt-4">
                    <div>
                      <div className="score">
                        {position}
                        <div className="avatar">
                          {/* <img alt='' src={avatar} /> */}
                          <img alt="" src={photo ? photo : avatar} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="trees">
                        {score}
                        <div className="avatar">
                          <img alt="" src="../../images/trees.svg" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="social-icons mt-4">
                    <p className="mt-4 mb-4">
                      <span>Share score with</span>
                    </p>

                    <div className="all-icons">
                      <a
                        className="icon"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          window.location.href
                        )}`}
                        // onClick = {()=>{console.log(window.location.href )}}
                      >
                        <i className="fab fa-lg fa-facebook-f"></i>
                      </a>
                      {/* <a className="icon" href={`instagram://library?AssetPath=${encodeURIComponent(window.location.href)}`}>
                                    <i className="fab fa-lg fa-instagram"></i>
                                </a>
                                <a className="icon" href={`https://plus.google.com/share?url=${encodeURIComponent(window.location.href)}`}>
                                    <i className="fab fa-lg fa-google"></i>
                                </a> */}
                      <a
                        className="icon"
                        href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                          window.location.href
                        )}`}
                      >
                        <i className="fab fa-lg fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-6">
              <div className="tab quiz">
                <h1 className="page-title mx-auto mt-4">
                  <span className="green">Oops!</span>
                </h1>

                <p className="time-left mt-4">
                  No More Quiz For Today. Thank You!
                </p>

                <div className="social-icons mt-4">
                  <div className="mt-4 mb-4">
                    <Link to="/">
                      <p className="go-back text-center">Go Back Home</p>
                    </Link>
                  </div>
                </div>

                {/* <div className="play-extra mt-4">
                        <button className="btn blue-btn-1"><span>Play Extra Quiz</span></button>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#156EF0"
            barColor="#019317"
          />
        </div>
      )}
    </>
  );
}
