import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';

import { authReducer } from './reducers/authReducers'
import {articalsReducer} from './reducers/newsReducers'
import {commentReducer} from './reducers/commentReducer'
import { quizReducer} from './reducers/quizReducers'
import { questionReducer } from './reducers/questionReducers';
import { SubmissionReducer } from './reducers/submissionReducer';
import { LeaderBoardReducer } from './reducers/leaderBoardReducers';


const rootReducer = combineReducers({
    auth: authReducer,
    news: articalsReducer,
    comment : commentReducer,
    quiz: quizReducer,
    question: questionReducer,
    submission: SubmissionReducer,
    leaderBoard: LeaderBoardReducer,
});

// const middleware = [thunkMiddleware]

const store = configureStore({ reducer: rootReducer });

export default store;