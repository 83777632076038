import {
    NEWS_GET_SUCCESS, NEWS_GET_FAIL,
}
    from '../types/newsType'


const articalState = {
    articalData: [],
    articalError: '',
    articalSuccessMessage: '',
    articalSuccessMessagee: ''
}


export const articalsReducer = (state = articalState, action) => {
    const { type, payload } = action

    if (type === NEWS_GET_SUCCESS) {
        return {
            ...state,
            articalData: payload.articalData,
            articalSuccessMessage: payload.articalSuccessMessage
        }
    }

    if(type === NEWS_GET_FAIL) {
        return {
            ...state,
            articalError: payload.error
        }
    }


    return state;
}