export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'

export const USER_SUCCESS_MESSAGE_CLEAR = 'USER_SUCCESS_MESSAGE_CLEAR'
export const USER_ERROR_MESSAGE_CLEAR = 'USER_ERROR_MESSAGE_CLEAR'

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_SET_SUCCESS_OTP = 'USER_SET_SUCCESS_OTP'
export const USER_SET_OTP_CLEAR = 'USER_SET_OTP_CLEAR'

export const USER_DATA_SET_SUCCESS = 'USER_DATA_SET_SUCCESS'
export const USER_DATA_CLEAR = 'USER_DATA_CLEAR'

export const CUR_USER_DATA_GET_SUCCESS = 'CUR_USER_DATA_GET_SUCCESS'
export const CUR_USER_DATA_GET_ERROR = 'CUR_USER_DATA_GET_ERROR'

export const CUR_USER_DATA_GET_CLEAR = 'CUR_USER_DATA_GET_CLEAR'


export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'


export const USER_GET_FAIL = 'USER_GET_FAIL'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'

export const SIGN_OUT = 'SIGN_OUT'




