import React, { useEffect, useState } from 'react'
import { userFPassword } from '../../../stores/actions/authAction'
import { useDispatch, useSelector } from 'react-redux';
import { userRegister } from '../../../stores/actions/authAction'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";

import {
    USER_REGISTER_FAIL, USER_REGISTER_SUCCESS, USER_SUCCESS_MESSAGE_CLEAR, USER_ERROR_MESSAGE_CLEAR,
    USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, SIGN_OUT, USER_SET_SUCCESS_OTP, USER_SET_OTP_CLEAR,
    USER_DATA_SET_SUCCESS, USER_DATA_CLEAR
} from '../../../stores/types/authType'



export default function ForgotPassword() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMessage, error, authenticate,  userOtp, userRData, myInfo } = useSelector(state => state.auth)


    const Data = {
        email: '',
    }

    const [state, setState] = useState(Data)

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(state)
        dispatch(userFPassword(state))
    }



    


    useEffect(() => {
        if (successMessage) {
            Swal.fire({
                icon: 'success',
                title: successMessage,
                showConfirmButton: false,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 2500
            }).then(() => {
                dispatch({ type: USER_SUCCESS_MESSAGE_CLEAR })
            })
        }

        if (error) {
            Swal.fire({
                icon: 'error',
                // title: 'Oops...',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                text: error
            }).then(() => {
                dispatch({ type: USER_ERROR_MESSAGE_CLEAR })
            })


        }
    }, [successMessage, error])




    return (

        <div>

            <div className="container-fluid">

                <div className="row d-flex justify-content-center align-items-center page-signup">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <h1 className="page-title mx-auto">
                            <span className="blue">Forgot</span>
                            <span className="green">Password</span>
                        </h1>

                        <form className="text-center" style={{ "maxWidth": "384px", "margin": "auto" }}>
                            <div className="form-floating mt-4 mb-4">
                                <input type="email" className="form-control" id="email"
                                    placeholder="Enter registered email" name="email"
                                    onChange={(e) => handleChange(e)} />
                                <label htmlFor="email">Enter registered email</label>
                            </div>

                            <button className="btn mt-4 mb-4 green-btn-2" onClick={handleSubmit}>
                                <span>Send Password Reset Link</span>
                            </button>

                        </form>
                    </div>
                    <div className="col-sm-3"></div>
                </div>

                {/* <footer className="footer fixed-footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}

            </div>

        </div>

    )
}
