import React, { useRef, useMemo } from 'react';

const OTPInput = ({ length, onChange }) => {
    // const inputRefs = Array.from({ length }, () => useRef(null));
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    // for (let i = 0; i < length; i++) {
    //     inputRefs[i] = useRef(null);
    // }

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        // Limit the input to a single digit
        const otpValue = value.replace(/\D/g, '').slice(0, 1);

        onChange(index, otpValue);

        // Move focus to the next input field if available
        if (otpValue && index < length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleInputKeyDown = (index, event) => {
        if (event.key === 'Backspace' && !event.target.value && index > 0) {
            // Move focus to the previous input field if the current field is empty and Backspace is pressed
            inputRefs[index - 1].current.focus();
        }
    };

    return (
        <div>
            {Array.from({ length }, (_, index) => (
                <div className="code">
                    <input
                        key={index}
                        ref={inputRefs[index]}
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        className="form-control"
                        id="email"
                        maxLength="1"
                        onChange={(e) => handleInputChange(index, e)}
                        onKeyDown={(e) => handleInputKeyDown(index, e)}
                    />
                </div>
            ))}
        </div>
    );
};

export default OTPInput;
