import React, { useEffect, useState } from 'react'
import { userRPassword } from '../../../stores/actions/authAction'
import { useDispatch, useSelector } from 'react-redux';
import { userRegister } from '../../../stores/actions/authAction'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";

import {
    USER_REGISTER_FAIL, USER_REGISTER_SUCCESS, USER_SUCCESS_MESSAGE_CLEAR, USER_ERROR_MESSAGE_CLEAR,
    USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, SIGN_OUT, USER_SET_SUCCESS_OTP, USER_SET_OTP_CLEAR,
    USER_DATA_SET_SUCCESS, USER_DATA_CLEAR
} from '../../../stores/types/authType'
import deCodeToken from 'jwt-decode'
import { useParams } from 'react-router-dom'
import axios from 'axios'

export default function ResetPassword() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMessage, error, authenticate, userOtp, userRData, myInfo  } = useSelector(state => state.auth)


    const { token } = useParams()
    const stateData = {
        password: '',
        confirmPassword: '',
    }

    const [state, setState] = useState(stateData)

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const tokenDecode = (token) => {
        const tokenDecoded = deCodeToken(token);
        const expTime = new Date(tokenDecoded.exp * 1000);
        if (new Date() > expTime) {
            return null
        }
        return tokenDecoded;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(state)
        if (state.password === '' || state.confirmPassword === '' || state.password !== state.confirmPassword) {
            // alert("Not Match The Password With The Re Enter Password")
            // console.log(50,state.password,state.confirmPassword);
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: "Not Match The Password With The Re Enter Password"
                }
            })
            return;
        }
        let tokenData = tokenDecode(token)
        if(tokenData === null){
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: {
                    error: "Invalid token"
                }
            })
            return;
        }
        dispatch(userRPassword({...state, id: tokenData._id}))
    }

    

    useEffect(() => {
        if (successMessage) {
            Swal.fire({
                icon: 'success',
                title: successMessage,
                showConfirmButton: false,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 2500
            }).then(() => {
                dispatch({ type: USER_SUCCESS_MESSAGE_CLEAR })
                navigate('/login')
            })
        }

        if (error) {
            Swal.fire({
                icon: 'error',
                // title: 'Oops...',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                text: error
            }).then(() => {
                dispatch({ type: USER_ERROR_MESSAGE_CLEAR })
            })


        }
    }, [successMessage, error])



    return (

        <div>

            <div className="container-fluid">

                <div className="row d-flex justify-content-center align-items-center page-signup">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <h1 className="page-title mx-auto">
                            <span className="blue">Reset</span>
                            <span className="green">Password</span>
                        </h1>

                        <form className="text-center" style={{ maxWidth: "384px", margin: "auto" }}>

                            <div className="form-floating mt-4 mb-4">
                                <input type="password" className="form-control" id="password"
                                    name="password"
                                    onChange={(e) => handleChange(e)}
                                    value={state.password} placeholder="Enter password" />
                                <label htmlFor="password">Enter new password</label>
                            </div>

                            <div className="form-floating mt-4 mb-4">
                                <input type="password" className="form-control" id="password2"
                                    name="confirmPassword"
                                    onChange={(e) => handleChange(e)}
                                    value={state.confirmPassword} placeholder="Re-Enter password" />
                                <label htmlFor="password2">Confirm password</label>
                            </div>

                            <button className="btn mt-4 mb-4 green-btn-2" onClick={handleSubmit}> 
                                <span>Reset Password</span>
                            </button>

                        </form>
                    </div>
                    <div className="col-sm-3"></div>
                </div>

                {/* <footer className="footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
            </div>

        </div>
    )
}
