import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from '../src/stores/index'

import { renderToString } from 'react-dom/server';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);


// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import { Provider } from 'react-redux';
// import store from '../src/stores/index';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// // import { Helmet, HelmetProvider } from 'react-helmet-async';


// const helmetContext = {}; // Create a context for helmet

// ReactDOM.render(
//   <Provider store={store}>
//     <HelmetProvider context={helmetContext}>
//       <App>
//         {/* Use Helmet to set document head properties */}
//         <Helmet>
//           <title>Hello World</title>
//           <link rel="canonical" href="https://www.tacobell.com/" />
//         </Helmet>
//         <h1>Hello World</h1>
//       </App>
//     </HelmetProvider>
//   </Provider>,
//   document.getElementById('root')
// );


