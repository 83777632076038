import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userRegister,
  userVerifyOtp,
  userRetry,
} from "../../../stores/actions/authAction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
  USER_SUCCESS_MESSAGE_CLEAR,
  USER_ERROR_MESSAGE_CLEAR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  SIGN_OUT,
  USER_SET_SUCCESS_OTP,
  USER_SET_OTP_CLEAR,
  USER_DATA_SET_SUCCESS,
  USER_DATA_CLEAR,
} from "../../../stores/types/authType";
import OTPInput from "../../Component/OTPInput/OTPInput";

import { ProgressBar } from "react-loader-spinner";

export default function VerifyCode() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { successMessage, error, authenticate, userOtp, userRData, myInfo } =
    useSelector((state) => state.auth);

    // console.log(userRData);

  // const history = useHistory();
  const location = useLocation();
  const [otp, setOTP] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [time, setTime] = useState(180); // 3 minutes in seconds
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let timer;

    if (isTimerRunning && time > 0) {
      timer = setInterval(() => setTime((prevTime) => prevTime - 1), 1000);
    }

    return () => clearInterval(timer);
  }, [isTimerRunning, time]);

  useEffect(() => {
    if (time === 0) {
      setIsTimerRunning(false);
      // Here, you can add your logic for handling expired OTP.
      // For this example, we'll simply navigate back to the signup page.
      // history.push('/');
      // console.log("ok");
    }
  }, [time]);

  const handleOTPChange = (index, value) => {
    const newOTP = otp.split("");
    newOTP[index] = value;
    setOTP(newOTP.join(""));
  };

  // console.log(userRData);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoader(true);
    // Simulating OTP verification
    if (otp === userOtp && isTimerRunning) {
      // OTP is correct, navigate to the verification success page
      dispatch(userVerifyOtp(userRData));
    } else {
      // OTP is incorrect, handle accordingly (e.g., show an error message)
      // console.log("Incorrect OTP");
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: {
          error: "Incorrect OTP",
        },
      });
    }
  };

  // Convert seconds to "mm:ss" format
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  function generateOTP() {
    const digits = "0123456789";
    let otp = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * digits.length);
      otp += digits[randomIndex];
    }

    return otp;
  }

  const RetryOtp = () => {
    let otp = generateOTP();
    dispatch(userRetry({ email: userRData.email, otp: otp })).then(
      (Response) => {
        dispatch({
          type: USER_SET_SUCCESS_OTP,
          payload: {
            userOtp: otp,
          },
        });
        setIsTimerRunning(true);
        setTime(180);
      }
    );
  };

  useEffect(() => {
    if (successMessage) {
      // Swal.fire({
      //     icon: 'success',
      //     title: successMessage,
      //     showConfirmButton: false,
      //     showClass: {
      //         popup: 'animate__animated animate__fadeInDown'
      //     },
      //     hideClass: {
      //         popup: 'animate__animated animate__fadeOutUp'
      //     },
      //     timer: 3000
      // }).then(() => {
      dispatch({ type: USER_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: USER_SET_OTP_CLEAR });
      dispatch({ type: USER_DATA_CLEAR });
      navigate("/login");
      // })
    }

    if (error) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        //title: "Oops...",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: error,
      }).then(() => {
        dispatch({ type: USER_ERROR_MESSAGE_CLEAR });
      });
    }
  }, [successMessage, error]);

  return (
    <div>
      <div className="container-fluid">
        {/* <header w3-include-html="header.html"></header> */}

        <div className="row d-flex justify-content-center align-items-center page-signup">
          <div className="col-sm-3"></div>
          <div className="col-sm-6 text-center">
            <h1 className="page-title mx-auto">
              <span className="blue">Verify</span>
              with
              <span className="green">Code</span>
            </h1>
            <p>
              Please enter the verification code sent to your &nbsp;
              <span className="blue-sm">
                {userRData.email || "***@gmail.com"}
              </span>
            </p>
            <form
              className="text-center"
              style={{ maxWidth: "384px", margin: "auto" }}
            >
              <div className="verify-codes">
                <OTPInput length={4} onChange={handleOTPChange} />
              </div>

              {isTimerRunning ? (
                <div className="mt-4 mb-4">
                  <span className="blue-sm">Retry in </span>
                  <span className="green-sm" style={{ color: "green" }}>
                    {formatTime(time)}
                  </span>
                </div>
              ) : (
                <div
                  className="mt-4 mb-4"
                  onClick={() => RetryOtp()}
                  style={{ cursor: "pointer" }}
                >
                  <a className="green-sm">Retry</a>
                </div>
              )}

              <button
                onClick={(e) => handleSubmit(e)}
                style={{ cursor: "pointer" }}
                className="btn mt-4 mb-4 green-btn-2"
              >
                <span>Verify Code</span>
              </button>

              <div className="mt-4 mb-4">
                <p className="go-back">Go Back Home</p>
              </div>
            </form>
          </div>
          <div className="col-sm-3"></div>
        </div>

        {/* <footer className="footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
      </div>

      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#156EF0"
            barColor="#019317"
          />
        </div>
      ) : null}
    </div>
  );
}
