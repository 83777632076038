import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  submissionCreate,
  submissionGet,
} from "../../../stores/actions/submissionAction";
import {
  leaderboardCreate,
  leaderboardGetAll,
  leaderboardGet,
} from "../../../stores/actions/leaderBoardAction";

import avatar from "../../../img/avatar.png";
import tree from "../../../img/trees.svg";
import { Link } from "react-router-dom";

import { ProgressBar } from "react-loader-spinner";

import { Helmet } from "react-helmet";
const { BASE_URL } = require("../../../config/dev");


export default function QuizComplete() {
  // const findMyPosition = (myData, leaderBoardAllData) => {

  //     console.log(20,myData, leaderBoardAllData)
  //     // Create a new array with the updated data
  //     const updatedLeaderboard = [...leaderBoardAllData, myData];

  //     // Sort the updated leaderboard data in descending order based on score
  //     const sortedLeaderboard = updatedLeaderboard.sort((a, b) => b.score - a.score);

  //     // Update positions in the sorted leaderboard
  //     const updatedLeaderboardWithPositions = sortedLeaderboard.map((entry, index) => ({
  //         ...entry,
  //         position: index + 1
  //     }));

  //     return updatedLeaderboardWithPositions;

  // };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [score, setScore] = useState(0);
  const [position, setPosition] = useState(0);
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    // dispatch(submissionGet());
    dispatch(leaderboardGetAll())
  }, []);

  const {
    submissionData,
    submissionError,
    submissionSuccessMessage,
    submissionSuccessMessagee,
  } = useSelector((state) => state.submission);
  const {
    leaderboardData,
    myLeaderBoardData,
    leaderboardError,
    leaderboardSuccessMessage,
  } = useSelector((state) => state.leaderBoard);
  const { myInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (leaderboardData) {
      const updatedLeaderboard = [...leaderboardData];
      const sortedLeaderboard = updatedLeaderboard.sort(
        (a, b) => b.score - a.score
      );

      sortedLeaderboard.map((se, index) => {
        if (se.userId === myInfo.Id) {
          let p = index + 1;
          setPosition(p);
          setPhoto(se.photo);
        }
      }, []);
    }
  }, [score && leaderboardData && leaderboardData.length > 0]);

  useEffect(() => {
    dispatch(leaderboardGetAll());
  }, []);

  useEffect(() => {
    if (myInfo) {
      dispatch(submissionGet(myInfo.Id));
    }
  }, [myInfo]);


  useEffect(() => {
    dispatch(leaderboardGet(myInfo.Id));
  }, [myInfo.Id]);

  /*Score Calculation */
  useEffect(() => {
    let total = 0;

    submissionData.map((sd) => {
      if (sd.correctAnswer === sd.userAnswer) {
        total += 1;
      }
    });

    setScore(total);
  }, [submissionData]);

  /* setting score to zero everytime reload */
  useEffect(() => {
    setScore(0);
  }, []);

  /*LeaderBoard Get or Create or Update wrt Score */
  useEffect(() => {
    if (leaderboardData && myInfo.Id !== undefined) {
      let data = {
        userId: myInfo.Id,
        score: score,
      };

      // let updatedLeaderboard = findMyPosition(data, leaderboardData);

      // let updatedData = { ...data };

      // updatedLeaderboard.forEach((entry) => {
      //     if (entry.userId === data.userId) {
      //         setPosition(entry.position);
      //         updatedData.position = entry.position;
      //     }
      // });

      // console.log(100, updatedData);

      /* DB CALL FOR UPDATE */
      // console.log("before updating leaderboard", data);
      dispatch(leaderboardCreate(data));
    }
  }, [score, leaderboardData, myInfo.Id]);



  return (
    <>

      <Helmet>
        <title>Planet Quizzer - Quiz-complete</title>
        <meta
          name="description"
          content="Complete your daily quiz and embark on a journey of knowledge and entertainment! Stay informed with the latest quiz trends and news while exploring captivating quizzes, thrilling trivia, and insightful articles on our quiz website's 'Completing My Daily Quiz' page. Test your knowledge, discover new facts, and stay ahead in the world of quizzes. Dive into a world of fun and learning – all in one place, every day!"
        />

        <meta property="og:title" content="Welcome to planetquizzer" />
        <meta
          property="og:description"
          content="Complete your daily quiz and embark on a journey of knowledge and entertainment! Stay informed with the latest quiz trends and news while exploring captivating quizzes, thrilling trivia, and insightful articles on our quiz website's 'Completing My Daily Quiz' page. Test your knowledge, discover new facts, and stay ahead in the world of quizzes. Dive into a world of fun and learning – all in one place, every day!"
        />
        <meta
          property="og:image"
          content={BASE_URL + "/" + "images/profilePic.png"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="image_src" href={BASE_URL + "/" + "images/profilePic.png"}></link>
      </Helmet>

      {
        leaderboardData &&
          leaderboardData.length > 0 &&
          submissionData &&
          submissionData.length > 0 ? (
          <>
            <div className="container-fluid">
              {/* <header w3-include-html="header.html"></header> */}

              <div className="row d-flex justify-content-center align-items-center page-signup">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <form id="regForm" action="/action_page.php">
                    {/* <!-- Circles which indicates the steps of the form: --> */}
                    <div className="all-steps">
                      <span className="step">
                        <span>01</span>
                      </span>
                      <span className="step"></span>
                      <span className="step"></span>
                      <span className="step"></span>
                      <span className="step"></span>
                      <span className="step"></span>
                      <span className="step"></span>
                      <span className="step">
                        <span>08</span>
                      </span>
                    </div>

                    <div className="tab quiz">
                      <h1 className="page-title mx-auto">
                        <span className="blue">My</span>
                        <span className="green">Score</span>
                      </h1>
                      <div className="my-scores">
                        <div>
                          <div className="score">
                            {position}
                            <div className="avatar">
                              <img alt="" src={photo || avatar} />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="trees">
                            {score}
                            <div className="avatar">
                              <img alt="" src={tree} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="social-icons">
                        <p className="mt-4 mb-4">
                          <span>Share score with</span>
                        </p>
                        <div className="all-icons">
                          <a
                            className="icon"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                              window.location.href
                            )}`}
                          >
                            <i className="fab fa-lg fa-facebook-f"></i>
                          </a>
                          {/* <a className="icon" href={`instagram://library?AssetPath=${encodeURIComponent(window.location.href)}`}>
                                            <i className="fab fa-lg fa-instagram"></i>
                                        </a>
                                        <a className="icon" href={`https://plus.google.com/share?url=${encodeURIComponent(window.location.href)}`}>
                                            <i className="fab fa-lg fa-google"></i>
                                        </a> */}
                          {/* <a
                          className="icon"
                          href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                            window.location.href
                          )}`}
                        >
                          <i className="fab fa-lg fa-linkedin-in"></i>
                        </a> */}

                          <a
                            className="icon"
                            href={`https://wa.me?text=I wanted you to see this ${encodeURIComponent(
                              window.location.href
                            )}`}
                          >
                            <i class="fab fa-whatsapp fa-lg" ></i>
                          </a>


                          <a
                            className="icon"
                            href={ `mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${encodeURIComponent(
                              window.location.href
                            )}`}
                          >
                            <i class="fa-solid fa-envelope"></i>
                          </a>

                        </div>
                      </div>

                      <Link to="/">
                        <p className="go-back text-center">Go Back Home</p>
                      </Link>

                      <div className="play-extra">
                        <button
                          className="btn blue-btn-1"
                          onClick={() => {
                            navigate(`/extra-quiz`);
                          }}
                        >
                          <span>Play Extra Quiz</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-2"></div>
              </div>

              {/* <footer className="footer fixed-footer">
                    <p className="text-light text-center">©Copyright 2023 PlanetQuizzer. All rights reserved.</p>
                </footer> */}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#156EF0"
              barColor="#019317"
            />
          </div>
        )}
    </>
  );
}
